import React, { useCallback, useState, useEffect, /* useCallback ,*/  /* useMemo, */ } from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import styles from "./styles.module.css";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';

import apiQueries from "../../services/apiQueries";
import client from "../../services/api";
import FormAddCategory from '../../components/FormAddCategory';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function AddCategoryDialog({ open, handleClose, refreshCategoriesList }) {
    const [loading, setLoading] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertType, setAlertType] = useState("error");
    const [alertText, setAlertText] = useState('');
    const [clearFormCounter, setClearFormCounter] = useState(2);

    const onSubmit = useCallback(({ formData }) => {
        const input = {
            ...formData,
        };
        setLoading(true);
        setAlertOpen(false);

        client
            .mutate({
                mutation: apiQueries.MUTATION_CREATE_CATEGORY,
                variables: {
                    input,
                },
            })
            .then(result => {
                // console.log("qwerty", result);
                setLoading(false);
                setAlertType("success");
                setAlertText("Category has been created!")
                setAlertOpen(true);

                refreshCategoriesList();
                setClearFormCounter(clearFormCounter + 1);
            })
            .catch(result => {
                setLoading(false);
                setAlertType("error");
                setAlertText("Failed to create category!")
                setAlertOpen(true);
                console.log(JSON.stringify(result, null, 2));
            });

    }, [setLoading, setAlertType, setAlertText, setAlertOpen, clearFormCounter, setClearFormCounter, refreshCategoriesList]);
    
    const onSubmitError = useCallback(() => {
        setAlertType("error");
        setAlertText("Please fill all required fields")
        setAlertOpen(true);
    }, [setAlertType, setAlertText, setAlertOpen,]);

    const alertClose = useCallback(() => {
        setAlertOpen(false);
    }, [setAlertOpen]);

    useEffect(() => {
        if (open) {
            setAlertOpen(false);
        }
    }, [open, setAlertOpen]);


    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Create category
                    </Typography>
                </Toolbar>
            </AppBar>
            <div>
                <Collapse in={alertOpen}>
                    <Alert severity={alertType}>
                        <AlertTitle>{alertType === "error" ? "Error" : "Done"}</AlertTitle>
                        {/* This is an error alert — <strong>check it out!</strong> */}
                        {alertText}
                    </Alert>
                </Collapse>
            </div>
            <div
                className={styles["scrollingContainer"]}
            >
                <div
                    className={styles["container"]}
                >
                    <FormAddCategory
                        // key={clearFormCounter}
                        clearFormCounter={clearFormCounter}
                        onSubmit={onSubmit}
                        onSubmitError={onSubmitError}
                        handleClose={handleClose}
                        alertClose={alertClose}
                    />
                </div>
            </div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Dialog>
    );
}
