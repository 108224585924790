import React, { /* useEffect, useState */ } from 'react';
import bgImage from "./../../bg_for_images.png";

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    // width: 120,
    height: 120,
    padding: 4,
    boxSizing: 'border-box',
};

const thumbInner = {
    margin: '0 auto',
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
    // backgroundColor: 'darkcyan',
    background: `url(${bgImage})`,
    backgroundPosition: `center`,
};


export default function ImagesThumbs({ previewUrls }) {
    const thumbs = previewUrls.map((url, index) => (
        <div style={thumb} key={index + "thumb"}>
            <div style={thumbInner}>
                <img
                    alt={"image thumb " + index}
                    src={url}
                    style={img}
                />
            </div>
        </div>
    ));

    return (
        <aside style={thumbsContainer}>
            {thumbs}
        </aside>
    );
}
