import create from 'zustand'
import formValidator from "../../utils/formValidator";
import produce from 'immer'


/* validation form */
const requiredFieldsSchema = {
    password: {
        rules: ["required", 'length:6:100'],
        customMessage: "Please enter the password (6 characters minimum)"
    },
    email: {
        rules: ["required", 'isEmail'],
        customMessage: "Please enter the email"
    },
    role: {
        rules: ["required"],
    },
};

const fieldsFactory = {
    create(attrs) {
        return {
            email: "",
            password: "",
            passwordConfirm: "",
            role: "user",
            ...attrs
        };
    },
};

/* ----------------------------------------------------------------------------------------- */

function setAllKeysTo(someobject, value) {
    for (let key in someobject) {
        someobject[key] = value;
    }
}

function setAllTouchedFactory(set) {
    return (isTouched=true) => {
        set(produce(state => {
            setAllKeysTo(state.touched, isTouched);
        }))
    }
}



function validatePasswordConfirm(pass, confirmPass) {
    const isValid = pass === confirmPass;
    let errorMessage = '';
    if (!isValid) {
        errorMessage = `Password confirmation doesn't match`
    }

    return { isValid, errorMessage };
}

function validateForm(formData, requiredFieldsSchema) {
    const { isValid:_isValid, errorsMessages:_errorsMessages } = formValidator.isValid(formData, requiredFieldsSchema);
    const { isValid: pcIsValid, errorMessage: pcError } = validatePasswordConfirm(formData.password, formData.passwordConfirm)

    const isValid = _isValid && pcIsValid;
    const errorsMessages = {
        ..._errorsMessages,
        passwordConfirm: pcError,
    };

    return { isValid, errorsMessages }
}



const defaultFormData = fieldsFactory.create();
const defaultTouchedFields = fieldsFactory.create();
setAllKeysTo(defaultTouchedFields, false);
const _defaultErrorsMessages = fieldsFactory.create()
setAllKeysTo(_defaultErrorsMessages, '');
const defaultErrorsMessages = {
    ..._defaultErrorsMessages,
    ...validateForm(defaultFormData, requiredFieldsSchema).errorsMessages,
};

const useStoreFormRequired = create((set, get) => ({
    isValid: false,
    touched: defaultTouchedFields,
    errorsMessages: defaultErrorsMessages,
    formData: fieldsFactory.create(),

    setDefault: () => {
        set({
            isValid: false,
            touched: defaultTouchedFields,
            errorsMessages: defaultErrorsMessages,
            formData: fieldsFactory.create(),
        });
    },
    setAllTouched: setAllTouchedFactory(set),
    onTouch: (fieldName) => {
        const { isValid, errorsMessages } = validateForm(
            get().formData,
            requiredFieldsSchema,
        );

        set(produce(state => {
            for (let key in errorsMessages) {
                state.errorsMessages[key] = errorsMessages[key];
            }

            state.isValid = isValid;
            state.touched[fieldName] = true;
        }));
    },
    onChange: (fieldName, value) => {
        const newFormData = {
            ...get().formData,
            [fieldName]: value,
        };

        const { isValid, errorsMessages } = validateForm(
            newFormData,
            requiredFieldsSchema,
        );

        set(produce(state => {
            state.formData[fieldName] = value;
            state.errorsMessages[fieldName] = errorsMessages[fieldName];
            state.isValid = isValid;
            state.touched[fieldName] = true;
        }));
    },
}))


export {
    useStoreFormRequired,
};
