import React from 'react';
import { Chip } from '@mui/material';
import styles from './styles.module.css';


export default function TagsList({ tagsData, onDelete }) {
    const handleDelete = (item) => {
        onDelete(item)
    }


    return (
        <div
        >
            {tagsData.map((item, index) => {
                return (
                    <div
                        key={'tag_' + item.id}
                        className={styles['chipWrapper']}
                    >
                        <Chip
                            
                            label={item.name}
                            value={item.id}
                            onDelete={() => handleDelete(item)}
                        />
                    </div>
                )
            })}
        </div>
    );
}