import { TextField } from '@mui/material';
import React, { useCallback } from 'react';
// import DebouncedTextField from '../DebouncedTextField';
import ImagesThumbs from '../ImagesThumbs';


function ImagePasteUrl({ value='', onTouch, onChange, label }) {
    const handleUrlTextFieldChange = useCallback((event) => {
        const newFileSource = event.target.value;
        onChange(newFileSource);
    }, [onChange]);

    const thumbsUrls = value === '' ? [] : [value];


    return (
        <div className="container">
            <TextField
                value={value}
                fullWidth
                id="outlined-basic"
                label={label}
                variant="outlined"
                sx={{
                    maxWidth: 700,
                }}
                onBlur={onTouch}
                onChange={handleUrlTextFieldChange}
            />
            <ImagesThumbs
                previewUrls={thumbsUrls}
            />
        </div>
    );
}

export default React.memo(ImagePasteUrl);
