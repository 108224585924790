import decorateWithEvents from '../utils/decorateWithEvents';


const storage = {
    namespace: "auth.v0.1",
    key(name) {
        return `${this.namespace}.${name}`;
    },
    save(name, value) {
        setTimeout(() => this.saveSync(name, value), 1);
    },
    saveSync(name, value) {
        localStorage.setItem(this.key(name), JSON.stringify(value));
    },
    load(name) {
        const key = this.key(name);
        const str = localStorage.getItem(key);
        let result = null;
        if (!str) {
            return result;
        }
        try {
            result = JSON.parse(str);
        } catch (e) {
            console.warn(`Cannot parse local storage value ${key}: ${str}`, e);
        }
        return result;
    },
};

const auth = decorateWithEvents({
    CHANGE: "change",
    apiJWT: storage.load("apiJWT"),
    // user: storage.load("user"),
    authenticate(apiJWT = null, user = null) {
        this.apiJWT = apiJWT;
        storage.saveSync("apiJWT", apiJWT);
        this.emit(this.CHANGE, this.getState());
    },
    getApiToken() {
        return this.apiJWT;
    },
    logout() {
        this.apiJWT = null;
        storage.saveSync("apiJWT", null);
        this.emit(this.CHANGE, this.getState());
    },
    getState(){
        return { 
            // user: this.user,
            apiJWT: this.apiJWT,
        };
    },
});


export default auth;
