import {
    isEmpty,
    isAlphanumeric,
    isPostalCode,
    isNumeric,
    isDecimal,
    isEmail,
    isBoolean,
    isInt,
    isFloat,
} from "validator";

const rules = {
    required: (value = "") => (!isEmpty(value) ? null : "Required"),
    isEmpty: (value = "") => (!isEmpty(value) ? null : "Cannot be left empty"),
    isAlphanumeric: (value = "") => (isAlphanumeric(value.replace(/ /g, "")) ? null : "Invalid"),
    isPostalCode: (value = "") => (isPostalCode(value, "any") ? null : "Invalid"),
    isEmail: (value = "") => (isEmail(value) ? null : "Invalid email address"),
    isNumeric: (value = "") => (isNumeric(value) ? null : "Should be a number"),
    isBoolean: (value = "") => (isBoolean(value) ? null : "Should be a boolean"),
    isId: (value = "") => (isInt(value, { min: 1 }) ? null : "Should be a round number bigger than zero"), /* custom */
    isLatitude: (value = "") => (isFloat(value, { min: -90, max: 90 }) ? null : "Should be a number betwen -90.0 and 90.0"), /* custom */
    isLongitude: (value = "") => (isFloat(value, { min: -180, max: 180 }) ? null : "Should be a number betwen -180.0 and 180.0"), /* custom */
    isNumber: (value = "") => {
        const isValid = isNumeric(value) || isDecimal(value);
        return isValid ? null : "Should be a number";
    },
    length: (value = "", options) => {
        const [min, max] = options;
        const { length } = value;
        if ((min !== undefined && length < parseInt(min, 10)) || length === undefined) {
            return `Minimum ${min} symbols required`;
        }
        if (max !== undefined && length > parseInt(max, 10)) {
            return `Maximum ${max} symbols`;
        }
        return null;
    },
    arrayLength: (value = [], options) => {
        const [min, max] = options;
        const { length } = value;
        if ((min !== undefined && length < parseInt(min, 10)) || length === undefined) {
            return `Minimum ${min} item${min > 1 ? "s" : ""} required`;
        }
        if (max !== undefined && length > parseInt(max, 10)) {
            return `Maximum ${max} items allowed`;
        }
        return null;
    },
}


export default rules;