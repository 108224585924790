import React from 'react'
import VideoItemsList from "./VideoItemsList";
import {
    useStoreSearchVideo,
} from "./stateStores";

const useStore = useStoreSearchVideo;

function VideoSearchItemsListHOC({ onClickEdit, refreshFunc }) {
    const categoryId = useStore(state => state.categoryId);
    // const loading = useStore(state => state.loading);
    const loadingRefetch = useStore(state => state.loadingRefetch);
    const items = useStore(state => state.items);
    const hasMore = useStore(state => state.hasMore);
    const error = useStore(state => state.error);

    const fetchMore = useStore(state => state.fetchMore);

    return (
        <VideoItemsList
            onClickEdit={onClickEdit}
            refreshFunc={refreshFunc}
            
            categoryId={categoryId}
            loadingRefetch={loadingRefetch}
            items={items}
            hasMore={hasMore}
            error={error}
            fetchMore={fetchMore}
        />
    )
}

export default VideoSearchItemsListHOC;
