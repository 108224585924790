const LOGIN_ROUTE =                 '/login';

const DBOARD_ROUTE =                '/dashboard';
const DBOARD_USERS_ROUTE =          DBOARD_ROUTE + '/users-list';
const DBOARD_VIDEOS_ROUTE =         DBOARD_ROUTE + '/video-list';
const DBOARD_CATEGORIES_ROUTE =     DBOARD_ROUTE + '/categories-list';
const HOME_ROUTE =                  DBOARD_VIDEOS_ROUTE;


export {
    LOGIN_ROUTE,
    DBOARD_ROUTE,
    DBOARD_USERS_ROUTE,
    DBOARD_CATEGORIES_ROUTE,
    DBOARD_VIDEOS_ROUTE,
    HOME_ROUTE,
};
