import React, { useState, useCallback } from 'react'
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import { Container } from '@mui/material';
import { Alert } from "@mui/material";

import formValidator from "../../utils/formValidator";
import { loginMutation } from "../../services/api";
import auth from "../../services/auth";
import { styled } from '@mui/system';
import {
  Redirect,
} from 'react-router-dom';
import {
  HOME_ROUTE,
} from "../../routes";


/* styles */
const FormWrapper = styled('div')({
  marginTop: 20,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
});

const StyledButton = styled(Button)({
  margin: '20px 0',
});

const StyledContainer = styled(Container)({
  marginTop: 80,
});

const stylesBarOpen = {
  width: '100%',
};

const stylesBarClosed = {
  display: 'none',
};


/* validation form */
const campaignSchema = {
  email: {
    rules: ["required", "isEmail"],
    customMessage: "Email is required"
  },
  password: {
    rules: ["required"],
    customMessage: "Password is required"
  },
};

const signInFactory = {
  create(attrs) {
    return {
      email: "",
      password: "",
      ...attrs
    };
  },
};

/* page */
const LoginScreen = () => {
  const [errorsMessages, setErrorsMessages] = useState({});
  const [formData, setFormData] = useState(signInFactory.create())
  const [touched, setTouched] = useState({});
  const [responseAlert, setResponseAlert] = useState({ open: false, text: "" });
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);


  const handleCloseResponseAlert = useCallback((event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setResponseAlert(false);
  }, [setResponseAlert]);


  const onChange = useCallback((value, name) => {
    const newFormData = {
      ...formData,
      [name]: value
    };
    setFormData(newFormData);

    const { errorsMessages } = formValidator.isValid(
      newFormData,
      campaignSchema
    );
    setErrorsMessages(errorsMessages);

    handleCloseResponseAlert();
  }, [handleCloseResponseAlert, formData, setFormData]);


  const onTouch = useCallback(name => {
    setTouched({ ...touched, [name]: true });

    const { errorsMessages } = formValidator.isValid(
      formData,
      campaignSchema
    );
    setErrorsMessages(errorsMessages);
  }, [touched, setTouched, formData]);


  const handleSubmit = useCallback(event => {
    event.preventDefault();

    setTouched({
      email: true,
      password: true,
    });

    const { isValid, errorsMessages } = formValidator.isValid(
      formData,
      campaignSchema
    );
    setErrorsMessages(errorsMessages);

    if (isValid) {
      // console.log("sendDataToServer");
      setLoading(true);
      loginMutation(formData)
        .then(response => {
          setLoading(false);
          // setFormData(signInFactory.create());
          setTouched({});

          /* auth.authenticate() will trigger redirect though event listener in App.js */
          const token = response.data.login.access_token;
          auth.authenticate(token); //1
          setRedirect(true);
        })
        .catch((errMessage) => {
          setLoading(false);
          console.error("custom errMessage", errMessage)
          setResponseAlert({
            open: true,
            text: errMessage,
          });
        });
    }
  }, [formData, setTouched, setResponseAlert, setErrorsMessages, setRedirect]);


  return (
    <StyledContainer
      component="main"
      maxWidth="xs"
    >
      <FormWrapper>
        <h1>Admin Panel</h1>
        <Alert onClose={handleCloseResponseAlert}
          severity="error"
          sx={
            responseAlert.open ? stylesBarOpen : stylesBarClosed
          }
        >
          {responseAlert.text}
        </Alert>
        <form
          noValidate
        >
          <TextField
            id="standard-helperText" label="email" type='email'
            fullWidth
            margin="normal"

            error={
              touched.email && errorsMessages.email
                ? true
                : false
            }

            onBlur={() => onTouch("email")}
            onChange={e => onChange(e.target.value, "email")}
            helperText={touched.email && errorsMessages.email}
          />
          <TextField
            id="filled-error"
            label="Password"
            type="password"
            required
            autoComplete="current-password"
            fullWidth
            margin="normal"

            error={
              touched.password && errorsMessages.password
                ? true
                : false
            }
            onBlur={() => onTouch("password")}
            onChange={e => onChange(e.target.value, "password")}
            helperText={touched.password && errorsMessages.password}
          />
          <StyledButton
            fullWidth
            type="submit"
            onClick={(event) => {
              handleSubmit(event);
            }}
            disabled={
              errorsMessages.email || errorsMessages.password || loading
                ? true
                : false
            }
            size='large'
            variant="contained"
            color="primary"
          >
            Login
          </StyledButton>
        </form>
      </FormWrapper>
      {redirect ? <Redirect to={HOME_ROUTE}/>: null}
    </StyledContainer>
  )
}


export default LoginScreen;
