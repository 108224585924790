import { gql } from "@apollo/client";

// api description
// https://api.stage.bytes.news/graphql

const apiQueries = {
    QUERY_CATEGORIES: gql`
        query {
            categories {
                id,
                name,
                logo,
                logoInactive,
                subcategories {
                    id,
                    name,
                },
            }
        }
        `,

    QUERY_SUBCATEGORIES: gql`
        query {
            subcategories {
                id,
                name,
                category {
                    id,
                    name,
                },
            }
        }
        `,


    QUERY_VIDEOS: gql`
        query(
            $limit: Float!,
            $page: Float!,
            $categoryId: Float,
            $search: String,
            $timestamp: DateTime,
        ) {
            videos(
                limit: $limit, page: $page, categoryId: $categoryId,
                search: $search, timestamp: $timestamp,
            ) {
                id,
                title,
                subtitle,
                categories {
                  id,
                  name,
                },
                tags {
                  id,
                  name,
                }
                subcategory {
                  id,
                  name,
                },
                url,
                source {
                  id
                  logoUrl,
                        sourceUrl,
                },
                date,
                location,
                supersedesPreviousId,
                copyright,
                newsScore,
                localityScore,
            }
        }
        `,

    // QUERY_USERS: gql`
    //     query {
    //         users {
    //             id,
    //             provider,
    //             email,
    //             roles,
    //             favorites {
    //                 id,
    //             },
    //             skipped {
    //                 id,
    //             },
    //             watched {
    //                 id,
    //             },
    //         }
    //     }
    //     `,
    QUERY_USERS: gql`
        query($input: GetUsersArgs!) {
            users(getUsersArgs: $input) {
                id,
                provider,
                email,
                roles,
            }
        }
        `,

    QUERY_TAGS: gql`
        query {
            tags {
                id,
                name,
            }
        }
        `,

    MUTATION_LOGIN: gql`
        mutation($email: String!, $password: String!) {
            login(email: $email, password: $password) {
                access_token,
            }
        }
        `,

    MUTATION_DELETE_VIDEO: gql`
        mutation($id: Float!) {
            deleteVideo(id: $id) {
                id,
            }
        }
        `,
/* --------------------------------------------------- */
    MUTATION_CREATE_CATEGORY: gql`
        mutation($input: CreateCategoryInput!) {
            createCategory(createCategoryData: $input) {
                id,
                name,
                logo,
                logoInactive,
            }
        }
        `,
    MUTATION_UPDATE_CATEGORY: gql`
        mutation($input: UpdateCategoryInput!) {
            updateCategory(updateCategoryData: $input) {
                id,
                name,
                logo,
                logoInactive,
            }
        }
        `,
    MUTATION_DELETE_CATEGORY: gql`
        mutation($id: Float!) {
            deleteCategory(id: $id) {
                id,
            }
        }
        `,
/* --------------------------------------------------- */
    MUTATION_CREATE_SUBCATEGORY: gql`
        mutation($input: CreateSubcategoryInput!) {
            createSubcategory(createSubcategoryData: $input) {
                id,
                name,
            }
        }
        `,
    MUTATION_UPDATE_SUBCATEGORY: gql`
        mutation($input: UpdateSubcategoryInput!) {
            updateSubcategory(updateSubcategoryData: $input) {
                id,
                name,
                category {
                    id,
                }
            }
        }
        `,
    MUTATION_DELETE_SUBCATEGORY: gql`
        mutation($id: Float!) {
            deleteSubcategory(id: $id) {
                id,
            }
        }
        `,
/* --------------------------------------------------- */
    MUTATION_CREATE_TAG: gql`
        mutation($input: CreateTagInput!) {
            createTag(createTagData: $input) {
                id,
                name,
            }
        }
        `,
    MUTATION_UPDATE_TAG: gql`
        mutation($input: UpdateTagInput!) {
            updateTag(updateTagData: $input) {
                id,
                name,
            }
        }
        `,
    MUTATION_DELETE_TAG: gql`
        mutation($id: Float!) {
            deleteTag(id: $id) {
                id,
            }
        }
        `,
/* --------------------------------------------------- */
    MUTATION_CREATE_USER: gql`
        mutation($input: CreateUserInput!) {
            createUser(createUserData: $input) {
                id,
                email,
                roles,
                provider,
            }
        }
        `,
    MUTATION_DELETE_USER: gql`
        mutation($input: DeleteUserInput!) {
            deleteUser(deleteUserData: $input) {
                id,
            }
        }
        `,
/* 
type UpdateUserPasswordInput {
    email: String!
    password: String!
}
*/
    MUTATION_CHANGE_PASSWORD: gql`
        mutation($input: UpdateUserPasswordInput!) {
            changePassword(changePasswordData: $input) {
                id,
            }
        }
        `,
/* 
type SetAdminAccessInput {
    id: Float!
    isAdmin: Boolean!
} 
*/
    MUTATION_SET_ADMIN_ACCESS: gql`
        mutation($input: SetAdminAccessInput!) {
            setAdminAccess(setAdminAccessData: $input) {
                id,
                email,
                roles,
                provider,
            }
        }
        `,

    MUTATION_CREATE_VIDEO_UPLOAD_URL: gql`
        mutation($videoTitle: String!) {
            createVideoUploadURL(videoTitle: $videoTitle) {
                uploadURL,
                videoURL,
            }
        }
        `,

// CreateVideoInput {
//     title: String!
//     subtitle: String!
//     categoryIds: [Float!]!
//     tagsIds: [Float!]!
//     subcategoryId: Float!
//     url: String
//     sourceUrl: String!
//     sourceLogoUrl: String!
//     date: DateTime
//     supersedesPreviousId: Float
//     copyright: String
//     newsScore: Float
//     localityScore: Float
//}
    MUTATION_CREATE_VIDEO: gql`
        mutation($input: CreateVideoInput!) {
            createVideo(createVideoInput: $input) {
                id,
            }
        }
        `,

    MUTATION_UPDATE_VIDEO: gql`
        mutation($input: UpdateVideoInput!) {
            updateVideo(updateVideoInput: $input) {
                id,
            }
        }
        `,
}


// const ExampleEnumType = new GraphQLEnumType({
//     name: 'ExampleEnum',
//     values: {
//       FOO: {
//         value: 11,
//       },
//       BAR: {
//         value: 23,
//       },
//     },
//   })


export default apiQueries;
