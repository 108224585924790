import React, { useCallback, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useStoreFormRequired } from './stateStore';
import { TextField } from '@mui/material';


export default function ChangePasswordConfirmation({ open, onCancel, onConfirm, itemData }) {
    /* useStoreFormRequired */
    const setDefaultRequred = useStoreFormRequired(state => state.setDefault);

    const isValidRequired = useStoreFormRequired(state => state.isValid);
    const setAllTouchedRequired = useStoreFormRequired(state => state.setAllTouched);
    const onTouchRequired = useStoreFormRequired(state => state.onTouch);
    const onChangeRequired = useStoreFormRequired(state => state.onChange);

    const passwordError = useStoreFormRequired(state => state.errorsMessages.password);
    const passwordConfirmError = useStoreFormRequired(state => state.errorsMessages.passwordConfirm);

    const passwordValue = useStoreFormRequired(state => state.formData.password);
    const passwordConfirmValue = useStoreFormRequired(state => state.formData.passwordConfirm);


    const passwordTouched = useStoreFormRequired(state => state.touched.password);
    const passwordConfirmTouched = useStoreFormRequired(state => state.touched.passwordConfirm);

    const _onConfirm = useCallback(() => {
        setAllTouchedRequired();
        if (isValidRequired) {
            onConfirm(itemData, passwordValue)
        }
    }, [itemData, onConfirm, isValidRequired, passwordValue, setAllTouchedRequired]);

    useEffect(() => {
        setDefaultRequred();
    }, [open, setDefaultRequred]);

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {`Do you want to change this user password?`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Id: {itemData.id}
                </DialogContentText>
                <DialogContentText>
                    Email: {itemData.email}
                </DialogContentText>
                <DialogContentText>
                    Provider: {itemData.provider}
                </DialogContentText>
                <DialogContentText>
                    Roles: {itemData.roles ? itemData.roles.join(", ") : 'cannot read roles'}
                </DialogContentText>
                <div>
                    <h4>Password</h4>
                    <TextField
                        error={
                            passwordTouched && passwordError
                                ? true
                                : false
                        }
                        // multiline
                        fullWidth
                        autoComplete="new-password"
                        label="Password"
                        variant="outlined"
                        // sx={{
                        //     maxWidth: 370,
                        // }}
                        type="password"
                        value={passwordValue}

                        onChange={useCallback((e) => onChangeRequired("password", e.target.value), [onChangeRequired])}
                        onBlur={useCallback(() => onTouchRequired("password"), [onTouchRequired])}
                        helperText={passwordTouched && passwordError}
                    />
                    <h4>Confirm password</h4>
                    <TextField
                        error={
                            passwordConfirmTouched && passwordConfirmError
                                ? true
                                : false
                        }
                        // multiline
                        fullWidth
                        autoComplete="new-password"
                        label="Confirm password"
                        variant="outlined"
                        // sx={{
                        //     maxWidth: 370,
                        // }}

                        type="password"
                        value={passwordConfirmValue}

                        onChange={useCallback((e) => onChangeRequired("passwordConfirm", e.target.value), [onChangeRequired])}
                        onBlur={useCallback(() => onTouchRequired("passwordConfirm"), [onTouchRequired])}
                        helperText={passwordConfirmTouched && passwordConfirmError}
                    />
                </div>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={onCancel}
                    autoFocus
                >
                    Cancel
                </Button>
                <Button
                    color="secondary"
                    onClick={_onConfirm}
                >
                    Change
                </Button>
            </DialogActions>
        </Dialog>
    );
}
