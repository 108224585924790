import React, { useCallback, useEffect, useState } from 'react'
import './styles.css'
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import AddVideoDialog from '../AddVideoDialog';
import UpdateVideoDialog from '../UpdateVideoDialog';
import CategoriesItems from "./CategoriesItems";
import VideoItemsListHOC from "./VideoItemsListHOC";
import VideoSearchItemsListHOC from "./VideoSearchItemsListHOC";
import {
    useStoreVideo,
    useStoreSearchVideo,
} from "./stateStores";


const VideoScreen = () => {
    /* -------------------------------------------------------------------- */
    const [searchString, setSearchString] = useState("");
    const handleSearchString = useCallback((e) => {
        setSearchString(e.target.value);
    }, [setSearchString]);

    /* -------------------------------------------------------------------- */
    const [openAddVideoDialog, setOpenAddVideoDialog] = useState(false);
    const handleOpenAddVideoDialog = useCallback(() => {
        setOpenAddVideoDialog(true);
    }, [setOpenAddVideoDialog]);
    
    const handleCloseAddVideoDialog = useCallback(() => {
        setOpenAddVideoDialog(false);
    }, [setOpenAddVideoDialog]);
    
    /* -------------------------------------------------------------------- */
    const [openEditVideoDialog, setOpenEditVideoDialog] = useState(false);
    const [editItem, setEditItem] = useState(false);
    const handleOpenEditVideoDialog = useCallback((itemData) => {
        setEditItem(itemData);
        setOpenEditVideoDialog(true);
    }, [setEditItem, setOpenEditVideoDialog]);
    
    const handleCloseEditVideoDialog = useCallback(() => {
        setOpenEditVideoDialog(false);
    }, [setOpenEditVideoDialog]);

    const categoryId = useStoreVideo(state => state.categoryId);
    const setCategoryIdAndFetch = useStoreVideo(state => state.setCategoryIdAndFetch);
    const clean = useStoreVideo(state => state.clean);
    const fetchMore = useStoreVideo(state => state.fetchMore);
    const setTimestamp = useStoreVideo(state => state.setTimestamp);
    
    const handleSetCategoryId = useCallback((id) => {
        // setTimestamp(new Date("2021-10-27T08:06:43.906Z"));
        setTimestamp(new Date());
        setCategoryIdAndFetch(id);
    }, [setCategoryIdAndFetch, setTimestamp]);
    
    const memoRefreshVideo = useCallback(() => {
        clean();
        setCategoryIdAndFetch(categoryId);
        fetchMore();
    }, [clean, fetchMore, categoryId, setCategoryIdAndFetch]);

    /* -------------------------------------------------------------------- */
    const cleanSearchVideo = useStoreSearchVideo(state => state.clean);
    const fetchMoreSearchVideo = useStoreSearchVideo(state => state.fetchMore);
    // const setTimestampSearchVideo = useStoreSearchVideo(state => state.setTimestamp);
    const setSearchStringSearchVideo = useStoreSearchVideo(state => state.setSearchString);

    useEffect(() => {
        cleanSearchVideo();
    }, [cleanSearchVideo])

    useEffect(() => {
        let timeoutId = null;

        if (searchString !== "") {
            timeoutId = setTimeout(() => {
                cleanSearchVideo();
                setSearchStringSearchVideo(searchString);
                fetchMoreSearchVideo();        
            }, 300);
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [cleanSearchVideo, setSearchStringSearchVideo, fetchMoreSearchVideo, searchString])

    const memoRefreshSearchVideo = useCallback(() => {
        cleanSearchVideo();
        setSearchStringSearchVideo(searchString);
        fetchMoreSearchVideo();
    }, [cleanSearchVideo, setSearchStringSearchVideo, searchString, fetchMoreSearchVideo]);
    /* -------------------------------------------------------------------- */


    return (
        <div className='list-main'>
            <div className='video-list-bar'>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenAddVideoDialog}
                >
                    + Add video
                </Button>
                <div
                    style={
                        (searchString === "" ?
                            {}
                            :
                            {
                                opacity: 0.4,
                                pointerEvents: 'none',
                            }
                        )
                    }
                >
                    <CategoriesItems
                        categoryId={categoryId}
                        setCategoryId={handleSetCategoryId}
                    />
                </div>

                <TextField
                    id="standard-search"
                    label="Search"
                    type="search"
                    // disabled={true}
                    sx={{
                        margin: '4px 0',
                        minWidth: 300,
                    }}

                    value={searchString}
                    onChange={handleSearchString}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
            </div>
            {searchString === "" ?
                <VideoItemsListHOC
                    onClickEdit={handleOpenEditVideoDialog}
                    refreshFunc={memoRefreshVideo}
                />
                :
                <VideoSearchItemsListHOC
                    onClickEdit={handleOpenEditVideoDialog}
                    refreshFunc={memoRefreshSearchVideo}
                />
            }
            <AddVideoDialog
                open={openAddVideoDialog}
                handleClose={handleCloseAddVideoDialog}
                refreshVideosList={memoRefreshVideo}
            />
            <UpdateVideoDialog
                itemData={editItem}
                open={openEditVideoDialog}
                handleClose={handleCloseEditVideoDialog}
                refreshVideosList={memoRefreshVideo}
            />
        </div>
    )
};


export default VideoScreen;
