import React, { useCallback, useEffect, useState/* useCallback ,*/ /* useEffect, */ /* useMemo, */ } from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import {
    createVideoMutation,
    uploadFile,
} from "../../services/api";

import styles from "./styles.module.css"
import FormAddVideo from '../../components/FormAddVideo';

import Backdrop from '@mui/material/Backdrop';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';


function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ maxWidth: 700, width: '50%', display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="inherit">
                    {`${Math.round(props.value,)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

// type CreateVideoInput {
//     title: String!
//     subtitle: String!
//     categoryIds: [Float!]!
//     tagsIds: [Float!]!
//     subcategoryId: Float!
//     url: String
//     sourceUrl: String!
//     sourceLogoUrl: String!
//     date: DateTime
//     supersedesPreviousId: Float
//     copyright: String
//     newsScore: Float
//     localityScore: Float
//     }


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function AddVideoDialog({ open, handleClose, refreshVideosList }) {
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertType, setAlertType] = useState("error");
    const [alertText, setAlertText] = useState('');
    const [clearFormCounter, setClearFormCounter] = useState(2);

    const onSubmit = useCallback(({ formData, formDataFiles }) => {
        // console.log("onSubmit", { formData, formDataFiles });

        const fullFormData = {
            ...formData,
        };
        const title = formData.title;
        
        setProgress(0);
        setLoading(true);
        setAlertOpen(false);

        Promise.all(Object.keys(formDataFiles).map((key) => {
            return new Promise((resolve, reject) => {
                const type = formDataFiles[key].type;
                const value = formDataFiles[key].value;

                if (type === "url") {
                    resolve({ key, url: value });
                } else /* type === file */ {
                    uploadFile(title, value, (progress) => {
                        setProgress(progress);
                    })
                        .then(({ url }) => {
                            resolve({ key, url });
                        })
                        .catch((error) => {
                            reject(error)
                        });
                }
            });
        }))
            .then(results => {
                results.forEach(({ key, url }) => {
                    fullFormData[key] = url;
                });
                // console.log('fullFormData', fullFormData);

                createVideoMutation(fullFormData)
                    .then(result => {
                        setLoading(false);
                        setAlertType("success");
                        setAlertText("Video has been uploaded!")
                        setAlertOpen(true);

                        refreshVideosList();
                        setClearFormCounter(clearFormCounter + 1)
                    })
                    .catch((error) => {
                        console.error(JSON.stringify(error, null, 2));

                        setLoading(false);
                        setAlertType("error");
                        setAlertText("Failed to create video!")
                        setAlertOpen(true);
                    });
            })
            .catch((error) => {
                console.error("AddVideoDialog error", error)
                setLoading(false);
                setAlertType("error");
                setAlertText("Failed to upload source logo or video file!")
                setAlertOpen(true);
            })
    }, [setLoading, setProgress, setAlertType, setAlertText, setAlertOpen, clearFormCounter, setClearFormCounter, refreshVideosList]);
    
    const onSubmitError = useCallback(() => {
        setAlertType("error");
        setAlertText("Please fill all required fields")
        setAlertOpen(true);
    }, [setAlertType, setAlertText, setAlertOpen,]);

    const alertClose = useCallback(() => {
        setAlertOpen(false);
    }, [setAlertOpen]);

    useEffect(() => {
        if (open) {
            setAlertOpen(false);
        }
    }, [open, setAlertOpen]);


    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Upload video
                    </Typography>
                </Toolbar>
            </AppBar>
            <div>
                <Collapse in={alertOpen}>
                    <Alert severity={alertType}>
                        <AlertTitle>{alertType === "error" ? "Error" : "Done"}</AlertTitle>
                        {/* This is an error alert — <strong>check it out!</strong> */}
                        {alertText}
                    </Alert>
                </Collapse>
            </div>
            <div
                className={styles["scrollingContainer"]}
            >
                <div
                    className={styles["container"]}
                >
                    <FormAddVideo
                        key={clearFormCounter}
                        onSubmit={onSubmit}
                        onSubmitError={onSubmitError}
                        handleClose={handleClose}
                        alertClose={alertClose}
                    />
                </div>
            </div>
            <Backdrop
                sx={{ flexDirection: "column", color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                // open={true}
                // onClick={handleClose}
            >
                <Typography>Uploading...</Typography>
                <LinearProgressWithLabel value={progress} />
            </Backdrop>
        </Dialog>
    );
}
