import React, { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from "./styles.module.css";
import ImagesThumbs from '../ImagesThumbs';


const fileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});


function ImageFileUploadBase64({ maxSize=1024 * 100, value, onChange }) {
    const [error, setError] = useState('');

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        maxFiles: 1,
        maxSize,
        onDragEnter: event => {
            // console.log("onDragEnter", event);
        },
        onDrop: acceptedFiles => {
            // console.log(acceptedFiles);
            if (acceptedFiles.length > 0) {

                fileToBase64(acceptedFiles[0])
                    .then(file => {
                        setError('');
                        onChange(file);
                    })
                    .catch(e => {
                        console.error(e);
                    });
            }
        },
        onDropRejected: () => {
            setError(`Maximum file upload size is ${maxSize / 1024}KB`);
        },
    });

    const thumbsUrls = useMemo(()=> {
        let items = []
        if (value !== "") {
            items.push(value);
        }

        return items;
    }, [value]);


    return (
        <div className="container">
            {error ? <div className={styles["helperText"]}>{error}</div> : null}
            <div {...getRootProps({
                    className: styles['dropzone'],
                })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <ImagesThumbs
                previewUrls={thumbsUrls}
            />
        </div>
    );
}


export default React.memo(ImageFileUploadBase64);
