import React, { useCallback, useState } from 'react'
import styles from './styles.module.css'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CategoriesConfigs from '../../components/CategoriesConfigs';
import SubcategoriesConfigs from '../../components/SubcategoriesConfigs';
import TagsConfigs from '../../components/TagsConfigs';


const CategoriesScreen = () => {
    const [tab, setTab] = useState('categories');
    const handleTabChange = useCallback((target, value) => {
        if (value !== null) {
            setTab(value);
        }
    }, [setTab]);


    return (
        <div
            className={styles['list-main']}
        >
            <div className={styles['video-list-bar']}>
                <ToggleButtonGroup
                    color="primary"
                    value={tab}
                    exclusive
                    onChange={handleTabChange}
                >
                    <ToggleButton
                        value={'categories'}
                    >
                        Categories
                    </ToggleButton>
                    <ToggleButton
                        value={'subcategories'}
                    >
                        Subcategories
                    </ToggleButton>
                    <ToggleButton
                        value={"tags"}
                    >
                        Tags
                    </ToggleButton>
                </ToggleButtonGroup>

            </div>

            {tab === 'categories' ?
                <CategoriesConfigs />
                :
                null
            }
            {tab === 'subcategories' ?
                <SubcategoriesConfigs />
                :
                null
            }
            {tab === 'tags' ?
                <TagsConfigs />
                :
                null
            }
        </div>
    )
};


export default CategoriesScreen;
