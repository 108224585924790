import React, { useCallback, useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';
// import apiQueries from "../../services/apiQueries";
// import client from "../../services/api";
// import { useQuery } from "@apollo/client";

import styles from "./styles.module.css"
// import ImagePasteUrl from '../ImagePasteUrl';
import ImageFileUploadBase64 from '../ImageFileUploadBase64';
// import DebouncedTextField from '../DebouncedTextField';

import {
    useStoreFormRequired,
} from "./stateStore";
import { TextField } from '@mui/material';


function correctFormData(rawFormData) {
    const correctedFormData = {
        ...rawFormData,
        // /* fix required fields */
        // categoryIds: rawFormData.categoryIds.map(item => parseInt(item)),
        // tagsIds: rawFormData.tagsIds.map(item => parseInt(item)),
        // subcategoryId: parseInt(rawFormData.subcategoryId),
    };
    
    // /* eliminate optional */
    // if (correctedFormData.copyright === '') {
    //     delete correctedFormData.copyright;
    // }

    // if (correctedFormData.newsScore === '') {
    //     delete correctedFormData.newsScore;
    // } else {
    //     correctedFormData.newsScore = parseInt(correctedFormData.newsScore);
    // }

    // if (correctedFormData.localityScore === '') {
    //     delete correctedFormData.localityScore;
    // } else {
    //     correctedFormData.localityScore = parseInt(correctedFormData.localityScore);
    // }
    
    return correctedFormData; 
}


export default function FormAddCategory({ onSubmit, onSubmitError, handleClose, alertClose, itemData=null }) {
    console.log('FormAddCategory', itemData);

    const _itemData = useMemo(() => {
        let ___itemData = null;
        if (itemData) {
            ___itemData = {...itemData};
            // ___itemData.logo = itemData.logo || '';
            // ___itemData.logoInactive = itemData.logoInactive  || '';
        }

        return ___itemData;
    }, [itemData]);

    /* useStoreFormRequired */
    const setDefaultRequred = useStoreFormRequired(state => state.setDefault);

    const formDataRequired = useStoreFormRequired(state => state.formData);
    const isValidRequired = useStoreFormRequired(state => state.isValid);
    const setAllTouchedRequired = useStoreFormRequired(state => state.setAllTouched);
    const onTouchRequired = useStoreFormRequired(state => state.onTouch);
    const onChangeRequired = useStoreFormRequired(state => state.onChange);

    const nameError = useStoreFormRequired(state => state.errorsMessages.name);
    const logoError = useStoreFormRequired(state => state.errorsMessages.logo);
    const logoInactiveError = useStoreFormRequired(state => state.errorsMessages.logoInactive);

    const nameValue = useStoreFormRequired(state => state.formData.name);
    const logoValue = useStoreFormRequired(state => state.formData.logo);
    const logoInactiveValue = useStoreFormRequired(state => state.formData.logoInactive);

    const nameTouched = useStoreFormRequired(state => state.touched.name);
    // const logoTouched = useStoreFormRequired(state => state.touched.logo);
    // const logoInactiveTouched = useStoreFormRequired(state => state.touched.logoInactive);


    const handleSubmit = useCallback(event => {
        event.preventDefault();
        /* setAllTouched */
        setAllTouchedRequired();

        // console.log("isValidRequired, isValidOptional, isValidFiles", isValidRequired, isValidOptional, isValidFiles);
        /* submit */
        if (isValidRequired) {
            // (formData, optionalFormData, filesFormData) => {
            const correctedFormData = correctFormData({
                ...formDataRequired,
            });
            // console.log("submit ", { formData: correctedFormData, formDataFiles });
            const id = _itemData ? _itemData.id : null;
            onSubmit({ formData: correctedFormData, updateId:id });
        } else {
            onSubmitError();
        }
    }, [
        _itemData,

        onSubmit,
        onSubmitError,

        formDataRequired,
        
        isValidRequired,

        setAllTouchedRequired,
    ]);

    // console.log("formDataRequired", formDataRequired);
    // console.log("formDataOptional", formDataOptional);
    /* listeners for alertClose */
    useEffect(() => {
        console.log("setDefault", _itemData);
        setDefaultRequred(_itemData);

        const unsub1 = useStoreFormRequired.subscribe(() => { alertClose() });

        return () => {
            unsub1();
        }
    }, [_itemData, alertClose, setDefaultRequred]);


    const onChangeLogo = useCallback((value) => {
        onChangeRequired('logo', value)
    }, [onChangeRequired]);

    const onChangeLogoInactive = useCallback((value) => {
        onChangeRequired('logoInactive', value)
    }, [onChangeRequired]);


    return (
        <>
            <h4>Name<span className={styles["requiredField"]}></span></h4>
            <TextField
                value={nameValue}
                fullWidth
                label="Title"
                variant="outlined"
                sx={{
                    maxWidth: 700,
                }}

                error={
                    nameTouched && nameError
                        ? true
                        : false
                }
                onBlur={useCallback(() => onTouchRequired("name"), [onTouchRequired])}
                onChange={useCallback(e => onChangeRequired('name', e.target.value), [onChangeRequired])}
                helperText={nameTouched && nameError}
            />
            <h4>Logo<span className={styles["requiredField"]}></span></h4>
            {logoError ? <div className={styles["helperText"]}>{logoError}</div> : null}
            <ImageFileUploadBase64
                maxSize={1024 * 40}
                value={logoValue}
                onChange={onChangeLogo}
            />
            <h4>Logo inactive<span className={styles["requiredField"]}></span></h4>
            {logoInactiveError ? <div className={styles["helperText"]}>{logoInactiveError}</div> : null}
            <ImageFileUploadBase64
                maxSize={1024 * 40}
                value={logoInactiveValue}
                onChange={onChangeLogoInactive}
            />
            <div
                className={styles['buttonsContainer']}
            >
                <Button
                    sx={{
                        margin: 5,
                    }}
                    size="large"
                    variant="contained"
                    color="inherit"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    sx={{
                        margin: 5,
                    }}
                    size="large"
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Save
                </Button>
            </div>
        </>
    );
}
