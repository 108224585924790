import React, { useCallback, useState } from 'react'

import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
// import { Paper } from '@mui/material';
// import IconButton from '@mui/material/IconButton';
// // import Typography from '@mui/material/Typography';
// // import TextField from '@mui/material/TextField';
// import CloseIcon from '@mui/icons-material/Close';

import styles from "./styles.module.css";

// import apiQueries from "../../services/apiQueries";
// import client from "../../services/api";
// import { useMutation, useQuery } from "@apollo/client";
import {
    Autocomplete,
    TextField,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


// const countries = [
//     { code: 'AD', label: 'Andorra', phone: '376' },
//     {
//       code: 'AE',
//       label: 'United Arab Emirates',
//       phone: '971',
//     },
//     { code: 'AF', label: 'Afghanistan', phone: '93' },
//     {
//       code: 'AG',
//       label: 'Antigua and Barbuda',
//       phone: '1-268',
//     },
//     { code: 'AI', label: 'Anguilla', phone: '1-264' },
//     { code: 'AL', label: 'Albania', phone: '355' },
//     { code: 'AM', label: 'Armenia', phone: '374' },
//     { code: 'AO', label: 'Angola', phone: '244' },
// ]

const Input = ({ categoriesItems, existedItems, loading, onAdd }) => {
    const [nameValue, setNameValue] = useState('');
    // const [categoryInputValue, setCategoryInputValue] = useState(null);
    const [categoryId, setCategoryId] = useState(null);
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const handleChange = useCallback((e) => {
        const value = e.target.value;
        setNameValue(value);
        if (value === "") {
            if (categoryId === null) {
                setButtonDisabled(true);
            }
        } else {
            if (categoryId !== null) {
                setButtonDisabled(false);
            }
        }
    }, [categoryId, setNameValue, setButtonDisabled]);

    const handleAdd = useCallback(() => {
        onAdd({ name: nameValue, categoryId });
    }, [onAdd, nameValue, categoryId]);

    const handleAutompleteChange = useCallback((e, v) => {
        // console.log("handleAutompleteChange", v)
        setNameValue(v);
        if (v === "") {
            if (categoryId === null) {
                setButtonDisabled(true);
            }
        } else {
            if (categoryId !== null) {
                setButtonDisabled(false);
            }
        }
    }, [categoryId, setNameValue, setButtonDisabled]);

    const handleAutompleteCategoryChange = useCallback((e, v) => {
        // console.log("handleAutompleteCategoryChange", v)
        // setCategoryInputValue(v);
        if (v === null) {
            if (nameValue === "") {
                setButtonDisabled(true);
            }
        } else {
            setCategoryId(v.id);
            if (nameValue !== "") {
                setButtonDisabled(false);
            }
        }
    }, [nameValue, setCategoryId, setButtonDisabled]);


    return (
        <div
            className={styles['inputWrapper']}
        >
            <Autocomplete
                id="country-select-demo"
                sx={{
                    // marginLeft: '40px',
                    width: '400px',
                }}
                options={categoriesItems}
                autoHighlight
                getOptionLabel={(option) => option.name}
                // value={categoryInputValue}
                onChange={handleAutompleteCategoryChange}

                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {/* <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt=""
                        /> */}
                        {option.name} {/* ({option.code}) +{option.phone} */}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Choose a category"
                        inputProps={{
                            ...params.inputProps,
                            // autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
            <Autocomplete
                freeSolo
                disableClearable
                options={existedItems.map((option) => option.name)}
                fullWidth
                getOptionLabel={(option) => option}
                inputValue={nameValue}
                onChange={handleAutompleteChange}

                sx={{
                    marginLeft: '20px',
                    width: '400px',
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="New subcategory name"
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                        }}
                        onChange={handleChange}
                    />
                )}
            />
            <Button
                // size="small"
                disabled={buttonDisabled || loading}
                variant="outlined"
                onClick={handleAdd}
                size = "small"
                sx={{
                    marginLeft: 2,
                    minWidth: 170,
                }}
            >
                Add subcategory
            </Button>
            {loading ? 
                <Box
                    sx={{ 
                        padding: 1,
                        display: 'flex',
                    }}
                >
                    <CircularProgress
                        color="inherit"
                    />
                </Box>
            : null}
        </div>
    )
};


export default Input;
