import create from 'zustand';
import apiQueries from "../../services/apiQueries";
import client from "../../services/api";


const _VIDEO_LIMIT = 24;
// const _VIDEO_LIMIT = 6;
const _VIDEO_FIRST_PAGE = 1;

const fetchVideos = ({
        limit = _VIDEO_LIMIT,
        page = _VIDEO_FIRST_PAGE,
        categoryId=null,
        search="",
        timestamp=null,
    }) => {

    return new Promise((resolve, reject) => {       
        client.query({
            query: apiQueries.QUERY_VIDEOS,
            variables: {
                limit,
                page,
                categoryId,
                search,
                timestamp,
            },
            fetchPolicy: "no-cache",
        })
            .then(result => {
                const listCopy = result.data.videos.map(item => item);
                resolve(listCopy);
            })
            .catch((e) => {
                console.log(e);
                reject(e);
            })
    });
}


function createStore() {
    return create((set, get) => ({
        _pageCounter: 1,
        
        loadingRefetch: false,
        loading: false,
        items: [],
        hasMore: true,
        timestamp: new Date(),
        error: null,
        categoryId: null,
        searchString: "",

        setTimestamp: (timestamp=new Date()) => {
            set({
                timestamp,
            });
        },

        setSearchString: (searchStringInput) => {
            set({
                searchString: searchStringInput,
            });
        },

        setCategoryIdAndFetch: (id) => {
            /* ----------------------------- */
            const _pageCounter = 1;
            set({
                loadingRefetch: true,
                categoryId: id,
            });
    
            const { timestamp, searchString } = get();
    
            const categoryId = id;
            fetchVideos({ page: _pageCounter, categoryId, timestamp, search: searchString })
                .then(newData => {
                    let hasMore = true;
                    if (newData.length < _VIDEO_LIMIT) {
                        hasMore = false;
                    }
    
                    set({
                        loadingRefetch: false,
                        items: [...newData],
                        hasMore,
                        _pageCounter: _pageCounter + 1,
                        error: null,
                    });
                })
                .catch((e) => {
                    // console.log("****", e)
                    set({
                        loadingRefetch: false,
                        error: e,
                    });
                })
            /* ----------------------------- */
        },
    
        clean: () => {
            set({
                loadingRefetch: false,
                loading: false,
                items: [],
                hasMore: true,
                _pageCounter: 1,
                searchString: "",
                timestamp: new Date(),
            });
        },
    
        fetchMore: () => {
            const categoryId = get().categoryId;
            const _pageCounter = get()._pageCounter;
            const { timestamp, searchString } = get();

            set({ loading: true });

            fetchVideos({ page: _pageCounter, categoryId, timestamp, search: searchString })
                .then(newData => {
                    let hasMore = true;
                    if (newData.length < _VIDEO_LIMIT) {
                        hasMore = false;
                    }

                    const items = get().items;
                    const filteredNewItems = newData.filter(item => {
                        return !items.some(existedItem => existedItem.id === item.id);
                    });

                    set({
                        loading: false,
                        items: [...items, ...filteredNewItems],
                        hasMore,
                        _pageCounter: _pageCounter + 1,
                        error: null,
                    });
                })
                .catch((e) => {
                    // console.log("****", e)
                    set({
                        loading: false,
                        error: e,
                    });
                })
        },
    }))
}

// useStore.subscribe((state) => { console.log("useStore", state) })


export default createStore;
