import React, { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';
import { MenuItem } from '@mui/material';
import { Select } from '@mui/material';


const errorText = "Cannot be empty";


export default function SubcategoryEditModal({ categories, open, onCancel, onConfirm, itemData }) {
    const [nameValue, setNameValue] = useState('');
    const [categoryIdValue, setCategoryIdValue] = useState('');
    const [nameTouched, setNameTouched] = useState(false);
    const [nameError, setNameError] = useState('');

    const [buttonDisabled, setButtonDisabled] = useState(false);

    useEffect(() => {
        if (itemData.category) {
            setCategoryIdValue(itemData.category.id )
        }
    }, [open, itemData, setCategoryIdValue]);

    useEffect(() => {
        // if (itemData) {
            setNameValue(itemData.name);

            if (itemData.name === "") {
                setNameTouched(true);
                setNameError(errorText);
                setButtonDisabled(true);
            } else {
                setNameTouched(false);
                setNameError("");
                setButtonDisabled(false);
            }
        // }
    }, [open, itemData, setNameValue, setNameTouched, setNameError, setButtonDisabled]);


    const onTouchRequired = useCallback(() => {
        setNameTouched(true);

        if (nameValue === "") {
            setNameError(errorText);
            setButtonDisabled(true);
        } else {
            setNameError('');
            setButtonDisabled(false);
        }
    }, [nameValue, setNameTouched, setNameError, setButtonDisabled]);;

    const onChangeRequired = useCallback((key, value) => {
        setNameValue(value);
        setNameTouched(true);

        if (value === "") {
            setNameError(errorText);
            setButtonDisabled(true);
        } else {
            setNameError('');
            setButtonDisabled(false);
        }
    }, [setNameValue, setNameTouched, setNameError, setButtonDisabled]);

    const handleChangeCategory = useCallback((e) => {
        setCategoryIdValue(e.target.value);
    }, [setCategoryIdValue]);

    const handleConfirm = useCallback(() => {
        // console.log("categoryIdValue", categoryIdValue);
        onConfirm({
            name: nameValue,
            categoryId: categoryIdValue,
        })
    } 
    , [onConfirm, categoryIdValue, nameValue])


    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {`Edit subcategory`}
            </DialogTitle>
            <DialogContent>
                <h4>Category</h4>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={categoryIdValue}
                    label="Age"
                    onChange={handleChangeCategory}
                >
                    {categories.map(category => {
                        return (
                            <MenuItem
                                key={category.id}
                                value={category.id}
                            >{category.name}</MenuItem>
                        )
                    })}
                </Select>
                <h4>Name</h4>
                <TextField
                    error={nameTouched && nameError ? true : false}
                    fullWidth
                    sx={{
                        minWidth: '300px',
                    }}
                    autoComplete="new-password"
                    label="Name"
                    variant="outlined"
                    type="text"
                    value={nameValue}

                    onChange={useCallback((e) => onChangeRequired("name", e.target.value), [onChangeRequired])}
                    onBlur={useCallback(() => onTouchRequired("name"), [onTouchRequired])}
                    helperText={nameTouched && nameError}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onCancel}
                    autoFocus
                >
                    Cancel
                </Button>
                <Button
                    disabled={buttonDisabled}
                    color="secondary"
                    onClick={handleConfirm}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog >
    );
}
