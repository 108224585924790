import React /* , { useMemo } */ from 'react';
import { useDropzone } from 'react-dropzone';
import styles from "./styles.module.css";


function VideoFileUpload({ onChange }) {
    // const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'video/*',
        maxFiles: 1,
        onDragEnter: event => {
            // console.log("onDragEnter", event);
        },
        onDrop: acceptedFiles => {
            onChange(acceptedFiles[0]);
        }
    });

    // const thumbsUrls = useMemo(()=> {
    //     const files = [];
    //     if (value) {
    //         files.push(value);
    //     }

    //     return files.map(file => URL.createObjectURL(file));
    // }, [value]);

    // useEffect(() => () => {
    //     // Make sure to revoke the data uris to avoid memory leaks
    //     // files.forEach(file => URL.revokeObjectURL(file.preview));
    // }, [files]);


    return (
        <div className="container">
            <div {...getRootProps({
                    className: styles['dropzone'],
                })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
        </div>
    );
}


export default React.memo(VideoFileUpload);
