import React, { /* useState, */ useCallback, useEffect, useMemo, useState } from 'react';
// import FormLabel from '@mui/material/FormLabel';

import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
// import { Paper } from '@mui/material';
// import IconButton from '@mui/material/IconButton';
// // import Typography from '@mui/material/Typography';
// // import TextField from '@mui/material/TextField';
// import CloseIcon from '@mui/icons-material/Close';

import styles from "./styles.module.css";

import apiQueries from "../../services/apiQueries";
import client from "../../services/api";
import { useMutation, useQuery } from "@apollo/client";
import TagsList from './TagsList';
import {
    Autocomplete,
    TextField,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

// import DebouncedTextField from "../DebouncedTextField";


function TagsInput({ tagsIdsValue, textFieldSx, onChange }) {
    const { loading,/*  error, */ data, refetch } = useQuery(apiQueries.QUERY_TAGS, {
        client,
    });

    const [mutateFunction, { /* data:dataMutation, */ loading:loadingMutation, /* errorMutation */ }] = useMutation(apiQueries.MUTATION_CREATE_TAG, {
        client,
        onCompleted({ createTag }) {
            if (createTag) {
                // console.log("createTag", createTag)
                refetch();
                onChange([...tagsIdsValue, createTag.id]);
            }
        },
        onError: (e) => {
            console.log("MUTATION_CREATE_TAG ERROR", e);
            console.log("MUTATION_CREATE_TAG ERROR", JSON.stringify(e, null, 2));
        }
    });
    // console.log("tags", data)
    // console.log("loadingMutation dataMutation", loadingMutation, dataMutation)

    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (inputValue === '') {
            setButtonDisabled(true);
        } else {
            setButtonDisabled(false);
        }
    }, [inputValue, setButtonDisabled]);

    const sortedTags = useMemo(() => {
        let list = [];
        if (data) {
            const listCopy = data.tags.map(item => item);
            list = listCopy.sort((a, b) => {
                return (a.id - b.id)
            });
        }

        return list;
    }, [data]);

    const handleChange = useCallback((e) => {
        // console.log("handleChange", e.target.value)
        setInputValue(e.target.value);
    }, [setInputValue]);

    const handleAutompleteChange = useCallback((e, v) => {
        // console.log("handleAutompleteChange", v)
        setInputValue(v);
    }, [setInputValue]);

    const handleAdd = useCallback(() => {
        const existingItem = sortedTags.find((item) => item.name === inputValue);

        if (existingItem) {
            const isAlreadyHere = tagsIdsValue.some((id) => id === existingItem.id);
            if (!isAlreadyHere) {
                onChange([...tagsIdsValue, existingItem.id]);
            };
        } else {
            mutateFunction({
                variables: {
                    input: {
                        name: inputValue
                    }
                }
            });
        }
    }, [tagsIdsValue, inputValue, sortedTags, onChange, mutateFunction]);

    const handleDelete = useCallback((itemToDelete) => {
        const filteredIds = tagsIdsValue.filter((id) => id !== itemToDelete.id);
        onChange(filteredIds)
    }, [tagsIdsValue, onChange]);

    // const onKeyDownHandler = (e) => {
    //     if (e.key === "Enter") {
    //         console.log('Enter')
    //         // Do work
    //     }
    // };

    const addedTags = useMemo(() => {
        let tags = sortedTags.filter(item => {
            return tagsIdsValue.some(id => parseInt(item.id) === parseInt(id));
        });

        return tags;
    }, [tagsIdsValue, sortedTags]);

    return (
        <>
            <TagsList
                tagsData={addedTags}
                onDelete={handleDelete}
            />

            <div
                className={styles['inputWrapper']}
            >
                <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    options={sortedTags.map((option) => option.name)}
                    fullWidth
                    sx={{
                        ...textFieldSx,
                    }}
                    getOptionLabel={(option) => option}
                    inputValue={inputValue}
                    onChange={handleAutompleteChange}
                    renderInput={(params) => (
                        // <DebouncedTextField
                        <TextField
                            {...params}
                            label="Search tag"
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                            }}
                            onChange={handleChange}
                        />
                    )}
                />
                <Button
                    // size="small"
                    disabled={buttonDisabled || loading || loadingMutation}
                    variant="outlined"
                    onClick={handleAdd}
                    sx={{
                        marginLeft: 2,
                    }}
                >
                    Add tag
                </Button>
                {loading || loadingMutation ? <CircularProgress
                                color="inherit"
                                sx={{
                                    marginLeft: 2,
                                    marginTop: 1,
                                    // padding: "15px",
                                }}
                            /> : null}
            </div>
        </>
    );
}


export default React.memo(TagsInput);
