import { TextField } from '@mui/material';
import React, { useCallback, useMemo } from 'react';


function correctNumberToStr(number) {
    let str = '';

    if (number < 10) {
        str = `0${number}`
    } else {
        str = `${number}`
    }

    return str;
}

function getDefaultTime(dateObject) {
    const date = dateObject || new Date();

    const getDate = date.getDate();
    const getFullYear = date.getFullYear();
    const getHours = date.getHours();
    const getMinutes = date.getMinutes();
    const getMonth = date.getMonth();

    const correctedDate = correctNumberToStr(getDate) || "01"
    const correctedMonth = correctNumberToStr(getMonth + 1) || "01"
    const correctedHours = correctNumberToStr(getHours) || "00"
    const correctedMinutes = correctNumberToStr(getMinutes) || "00"

    return `${getFullYear}-${correctedMonth}-${correctedDate}T${correctedHours}:${correctedMinutes}`;
}


function DatePicker({ dateObject, onChange }) {
    const handleTimeChange = useCallback((e) => {
        const value = e.target.value;
        const newDate = new Date(value);
        onChange(newDate);
        // console.log("handleTimeChange", value);
        // console.log("handleTimeChange newDate", newDate);
    }, [onChange]);

    const dateValue = useMemo(() => {
        return getDefaultTime(dateObject);
    }, [dateObject])


    return (
        <TextField
            // id="datetime-local"
            key={dateValue}
            // label="Your local time"
            type="datetime-local"
            /*  "yyyy-MM-ddThh:mm" followed by optional ":ss" or ":ss.SSS" */
            defaultValue={dateValue}
            sx={{ width: 250 }}
            InputLabelProps={{
                shrink: true,
            }}
            onBlur={e => console.log("onBlur", e)}
            onChange={handleTimeChange}
        />
    );
}


export default DatePicker;
