import React, { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';


const errorText = "Cannot be empty";


export default function TagEditModal({ open, onCancel, onConfirm, itemData }) {
    const [nameValue, setNameValue] = useState('');
    const [nameTouched, setNameTouched] = useState(false);
    const [nameError, setNameError] = useState('');

    const [buttonDisabled, setButtonDisabled] = useState(false);


    useEffect(() => {
        // if (itemData) {
            setNameValue(itemData.name);

            if (itemData.name === "") {
                setNameTouched(true);
                setNameError(errorText);
                setButtonDisabled(true);
            } else {
                setNameTouched(false);
                setNameError("");
                setButtonDisabled(false);
            }
        // }
    }, [open, itemData, setNameValue, setNameTouched, setNameError, setButtonDisabled]);


    const onTouchRequired = useCallback(() => {
        setNameTouched(true);

        if (nameValue === "") {
            setNameError(errorText);
            setButtonDisabled(true);
        } else {
            setNameError('');
            setButtonDisabled(false);
        }
    }, [nameValue, setNameTouched, setNameError, setButtonDisabled]);;

    const onChangeRequired = useCallback((key, value) => {
        setNameValue(value);
        setNameTouched(true);

        if (value === "") {
            setNameError(errorText);
            setButtonDisabled(true);
        } else {
            setNameError('');
            setButtonDisabled(false);
        }
    }, [setNameValue, setNameTouched, setNameError, setButtonDisabled]);


    return (
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {`Edit tag`}
            </DialogTitle>
            <DialogContent>
                <h4>Name</h4>
                <TextField
                    error={nameTouched && nameError ? true : false}
                    fullWidth
                    sx={{
                        minWidth: '300px',
                    }}
                    autoComplete="new-password"
                    label="Name"
                    variant="outlined"
                    type="text"
                    value={nameValue}

                    onChange={useCallback((e) => onChangeRequired("name", e.target.value), [onChangeRequired])}
                    onBlur={useCallback(() => onTouchRequired("name"), [onTouchRequired])}
                    helperText={nameTouched && nameError}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onCancel}
                    autoFocus
                >
                    Cancel
                </Button>
                <Button
                    disabled={buttonDisabled}
                    color="secondary"
                    onClick={useCallback(() => onConfirm({ name: nameValue }), [onConfirm, nameValue])}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog >
    );
}
