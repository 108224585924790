import React, { useCallback, useState, useMemo } from 'react'
import Input from './Input';
import Table from './Table';
import styles from './styles.module.css';
import { Alert, Snackbar } from '@mui/material';
import SubcategoryEditModal from '../Modals/SubcategoryEditModal';
import SubcategoryDeleteModal from '../Modals/SubcategoryDeleteModal';
import apiQueries from "../../services/apiQueries";
import client from "../../services/api";
import { useMutation, useQuery } from "@apollo/client";


const SubcategoriesConfigs = () => {
    const { loading: fetchCategoriesLoading,/*  error, */ data: dataCategories, refetch: refetchCategories } = useQuery(apiQueries.QUERY_CATEGORIES, {
        client,
        fetchPolicy: 'no-cache',
    });

    const sortedCategoriesItems = useMemo(() => {
        let list = [];
        if (dataCategories) {
            const listCopy = dataCategories.categories.map(item => item);
            list = listCopy.sort((a, b) => {
                // return (a.id - b.id)
                return (b.id - a.id)
            });
        }

        return list;
    }, [dataCategories]);

    const { loading: fetchLoading, /* error, */ data: fetchData, refetch } = useQuery(apiQueries.QUERY_SUBCATEGORIES, {
        client,
        fetchPolicy: 'no-cache',
    });
// console.log("error", JSON.stringify(error, null, 2));
    const sortedItems = useMemo(() => {
        let list = [];
        if (fetchData) {
            const listCopy = fetchData.subcategories.map(item => {
                return {
                    ...item,
                    categoryName: item.category.name,
                };
            });
            list = listCopy.sort((a, b) => {
                // return (a.id - b.id)
                return (b.id - a.id)
            });
        }

        return list;
    }, [fetchData]);

    const [createSuccessCnt, setCreateSuccessCnt] = useState(0);
    /* -------------------------------------------------------------------------------------------- */
    const [mutateCreate, { /* data:dataMutation, */ loading: createLoading, /* errorMutation */ }] = useMutation(apiQueries.MUTATION_CREATE_SUBCATEGORY, {
        client,
        onCompleted({ createSubcategory }) {
            if (createSubcategory) {
                // console.log("createSubcategory", createSubcategory)
                refetch();
                refetchCategories();

                setAlertOpen(true);
                setAlertSeverity('success');
                setAlertMessage('Subcategory has been created');

                setCreateSuccessCnt(createSuccessCnt + 1);
            }
        },
        onError: (e) => {
            setAlertOpen(true);
            setAlertSeverity('error');
            setAlertMessage('Failed to create subcategory');

            if (e.graphQLErrors) {
                if (e.graphQLErrors[0]) {
                    if (e.graphQLErrors[0].message) {
                        setAlertMessage(e.graphQLErrors[0].message);
                    }
                }
            }
            // "graphQLErrors": [
            //     {
            //       "message": "Subcategory already exists"
            console.log("MUTATION_CREATE_SUBCATEGORY ERROR", e);
            console.log("MUTATION_CREATE_SUBCATEGORY ERROR", JSON.stringify(e, null, 2));
        }
    });
    /* -------------------------------------------------------------------------------------------- */
    const [mutateUpdate, /*{ data:dataMutation, */ /* loading: updateLoading, */ /* errorMutation }*/] = useMutation(apiQueries.MUTATION_UPDATE_SUBCATEGORY, {
        client,
        onCompleted({ updateSubcategory }) {
            if (updateSubcategory) {
                // console.log("updateSubcategory", updateSubcategory)
                refetch();
                refetchCategories();
                setAlertOpen(true);
                setAlertSeverity('success');
                setAlertMessage('Subcategory has been updated');
            }
        },
        onError: (e) => {
            setAlertOpen(true);
            setAlertSeverity('error');
            setAlertMessage('Failed to update subcategory');

            console.log("MUTATION_UPDATE_SUBCATEGORY ERROR", e);
            console.log("MUTATION_UPDATE_SUBCATEGORY ERROR", JSON.stringify(e, null, 2));
        }
    });
    /* -------------------------------------------------------------------------------------------- */
    const [mutateDelete, /*{ data:dataMutation, */ /* loading: deleteLoading, */ /* errorMutation }*/] = useMutation(apiQueries.MUTATION_DELETE_SUBCATEGORY, {
        client,
        onCompleted({ deleteSubcategory }) {
            if (deleteSubcategory) {
                // console.log("deleteSubcategory", deleteSubcategory)
                refetch();
                refetchCategories();

                setAlertOpen(true);
                setAlertSeverity('success');
                setAlertMessage('Subcategory has been deleted');
            }
        },
        onError: (e) => {
            setAlertOpen(true);
            setAlertSeverity('error');
            setAlertMessage('Failed to delete subcategory');

            console.log("MUTATION_DELETE_SUBCATEGORY ERROR", e);
            console.log("MUTATION_DELETE_SUBCATEGORY ERROR", JSON.stringify(e, null, 2));
        }
    });
    /* -------------------------------------------------------------------------------------------- */


    /* -------------------------------------------------------------------------------------------- */
    const [selectedItem, setSelectedItem] = useState({});
    /* -------------------------------------------------------------------------------------------- */
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState('error');
    const [alertMessage, setAlertMessage] = useState('');

    const handleCloseAlert = useCallback(() => {
        setAlertOpen(false);
    }, [setAlertOpen]);

    /* -------------------------------------------------------------------------------------------- */

    /* -------------------------------------------------------------------------------------------- */
    const [openDelete, setOpenDelete] = useState(false);
    const handleOpenDelete = useCallback((rowData) => {
        setSelectedItem(rowData);
        setOpenDelete(true);
    }, [setOpenDelete, setSelectedItem]);

    const handleCloseDelete = useCallback(() => {
        setOpenDelete(false);
    }, [setOpenDelete]);

    const handleDelete = useCallback(() => {
        // console.log("handleDelete", selectedItem);

        setOpenDelete(false);
        mutateDelete({
            variables: {
                id: selectedItem.id,
            },
        });
    }, [selectedItem, setOpenDelete, mutateDelete]);
    /* -------------------------------------------------------------------------------------------- */

    /* -------------------------------------------------------------------------------------------- */
    const [openEdit, setOpenEdit] = useState(false);
    const handleOpenEdit = useCallback((rowData) => {
        setSelectedItem(rowData);
        setOpenEdit(true);
    }, [setOpenEdit, setSelectedItem]);

    const handleCloseEdit = useCallback(() => {
        setOpenEdit(false);
    }, [setOpenEdit]);

    const handleEdit = useCallback(({ name, categoryId }) => {
        // console.log("handleEdit", selectedItem);
        // console.log("name", name);
        console.log("categoryId", categoryId);

        setOpenEdit(false);
        const input = {
            id: selectedItem.id,
            name,
            categoryId,
        };
        mutateUpdate({
            variables: {
                input,
            },
        });
    }, [selectedItem, setOpenEdit, mutateUpdate]);
    /* -------------------------------------------------------------------------------------------- */
    const handleCreate = useCallback(({ name, categoryId }) => {
        const input = {
            name,
            categoryId,
        };
        mutateCreate({
            variables: {
                input,
            },
        });
    }, [mutateCreate]);
    /* -------------------------------------------------------------------------------------------- */


    return (<>
        <Input
            key={`input` + createSuccessCnt}
            categoriesItems={sortedCategoriesItems}
            existedItems={sortedItems}
            loading={fetchLoading || createLoading || fetchCategoriesLoading}
            onAdd={handleCreate}
        />
        <div
            className={styles["tableWrapper"]}
        >
            {fetchLoading ?
                <p>Loading...</p>
                :
                <Table
                    rowsData={sortedItems}
                    onEdit={handleOpenEdit}
                    onDelete={handleOpenDelete}
                />
            }
        </div>
        <SubcategoryEditModal
            categories={sortedCategoriesItems}
            itemData={selectedItem}
            open={openEdit}
            onCancel={handleCloseEdit}
            onConfirm={handleEdit}
        />
        <SubcategoryDeleteModal
            itemData={selectedItem}
            open={openDelete}
            onCancel={handleCloseDelete}
            onConfirm={handleDelete}
        />
        <Snackbar
            open={alertOpen}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
        >
            <Alert
                onClose={handleCloseAlert}
                severity={alertSeverity}
                sx={{ width: '100%' }}
            >
                {alertMessage}
            </Alert>
        </Snackbar>
    </>)
};


export default SubcategoriesConfigs;
