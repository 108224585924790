import React, { useCallback, useState, useMemo } from 'react'
import Input from './Input';
import Table from './Table';
import styles from './styles.module.css';
import { Alert, Snackbar } from '@mui/material';
import TagEditModal from '../Modals/TagEditModal';
import TagDeleteModal from '../Modals/TagDeleteModal';
import apiQueries from "../../services/apiQueries";
import client from "../../services/api";
import { useMutation, useQuery } from "@apollo/client";


const TagsConfigs = () => {
    const { loading: fetchLoading,/*  error, */ data: fetchData, refetch } = useQuery(apiQueries.QUERY_TAGS, {
        client,
        fetchPolicy: 'no-cache',
    });

    const sortedItems = useMemo(() => {
        let list = [];
        if (fetchData) {
            const listCopy = fetchData.tags.map(item => item);
            list = listCopy.sort((a, b) => {
                // return (a.id - b.id)
                return (b.id - a.id)
            });
        }

        return list;
    }, [fetchData]);

    /* -------------------------------------------------------------------------------------------- */
    const [mutateCreate, { /* data:dataMutation, */ loading: createLoading, /* errorMutation */ }] = useMutation(apiQueries.MUTATION_CREATE_TAG, {
        client,
        onCompleted({ createTag }) {
            if (createTag) {
                // console.log("createTag", createTag)
                refetch();

                setAlertOpen(true);
                setAlertSeverity('success');
                setAlertMessage('Tag has been created');
            }
        },
        onError: (e) => {
            setAlertOpen(true);
            setAlertSeverity('error');
            setAlertMessage('Failed to create tag');

            if (e.graphQLErrors) {
                if (e.graphQLErrors[0]) {
                    if (e.graphQLErrors[0].message) {
                        setAlertMessage(e.graphQLErrors[0].message);
                    }
                }
            }
            // "graphQLErrors": [
            //     {
            //       "message": "Tag already exists"
            console.log("MUTATION_CREATE_TAG ERROR", e);
            console.log("MUTATION_CREATE_TAG ERROR", JSON.stringify(e, null, 2));
        }
    });
    /* -------------------------------------------------------------------------------------------- */
    const [mutateUpdate, /*{ data:dataMutation, */ /* loading: updateLoading, */ /* errorMutation }*/] = useMutation(apiQueries.MUTATION_UPDATE_TAG, {
        client,
        onCompleted({ updateTag }) {
            if (updateTag) {
                // console.log("updateTag", updateTag)
                refetch();
                setAlertOpen(true);
                setAlertSeverity('success');
                setAlertMessage('Tag has been updated');
            }
        },
        onError: (e) => {
            setAlertOpen(true);
            setAlertSeverity('error');
            setAlertMessage('Failed to update tag');

            console.log("MUTATION_UPDATE_TAG ERROR", e);
            console.log("MUTATION_UPDATE_TAG ERROR", JSON.stringify(e, null, 2));
        }
    });
    /* -------------------------------------------------------------------------------------------- */
    const [mutateDelete, /*{ data:dataMutation, */ /* loading: deleteLoading, */ /* errorMutation }*/] = useMutation(apiQueries.MUTATION_DELETE_TAG, {
        client,
        onCompleted({ deleteTag }) {
            if (deleteTag) {
                // console.log("deleteTag", deleteTag)
                refetch();

                setAlertOpen(true);
                setAlertSeverity('success');
                setAlertMessage('Tag has been deleted');
            }
        },
        onError: (e) => {
            setAlertOpen(true);
            setAlertSeverity('error');
            setAlertMessage('Failed to delete tag');

            console.log("MUTATION_DELETE_TAG ERROR", e);
            console.log("MUTATION_DELETE_TAG ERROR", JSON.stringify(e, null, 2));
        }
    });
    /* -------------------------------------------------------------------------------------------- */


    /* -------------------------------------------------------------------------------------------- */
    const [selectedItem, setSelectedItem] = useState({});
    /* -------------------------------------------------------------------------------------------- */
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState('error');
    const [alertMessage, setAlertMessage] = useState('');

    const handleCloseAlert = useCallback(() => {
        setAlertOpen(false);
    }, [setAlertOpen]);

    /* -------------------------------------------------------------------------------------------- */

    /* -------------------------------------------------------------------------------------------- */
    const [openDelete, setOpenDelete] = useState(false);
    const handleOpenDelete = useCallback((rowData) => {
        setSelectedItem(rowData);
        setOpenDelete(true);
    }, [setOpenDelete, setSelectedItem]);

    const handleCloseDelete = useCallback(() => {
        setOpenDelete(false);
    }, [setOpenDelete]);

    const handleDelete = useCallback(() => {
        // console.log("handleDelete", selectedItem);

        setOpenDelete(false);
        mutateDelete({
            variables: {
                id: selectedItem.id,
            },
        });
    }, [selectedItem, setOpenDelete, mutateDelete]);
    /* -------------------------------------------------------------------------------------------- */

    /* -------------------------------------------------------------------------------------------- */
    const [openEdit, setOpenEdit] = useState(false);
    const handleOpenEdit = useCallback((rowData) => {
        setSelectedItem(rowData);
        setOpenEdit(true);
    }, [setOpenEdit, setSelectedItem]);

    const handleCloseEdit = useCallback(() => {
        setOpenEdit(false);
    }, [setOpenEdit]);

    const handleEdit = useCallback(({ name }) => {
        // console.log("handleEdit", selectedItem);
        // console.log("name", name);

        setOpenEdit(false);
        const input = {
            id: selectedItem.id,
            name,
        };
        mutateUpdate({
            variables: {
                input,
            },
        });
    }, [selectedItem, setOpenEdit, mutateUpdate]);
    /* -------------------------------------------------------------------------------------------- */
    const handleCreate = useCallback(({ name }) => {
        const input = {
            name,
        };
        mutateCreate({
            variables: {
                input,
            },
        });
    }, [mutateCreate]);
    /* -------------------------------------------------------------------------------------------- */


    return (<>
        <Input
            existedItems={sortedItems}
            loading={fetchLoading || createLoading}
            onAdd={handleCreate}
        />
        <div
            className={styles["tableWrapper"]}
        >
            {fetchLoading ?
                <p>Loading...</p>
                :
                <Table
                    rowsData={sortedItems}
                    onEdit={handleOpenEdit}
                    onDelete={handleOpenDelete}
                />
            }
        </div>
        <TagEditModal
            itemData={selectedItem}
            open={openEdit}
            onCancel={handleCloseEdit}
            onConfirm={handleEdit}
        />
        <TagDeleteModal
            itemData={selectedItem}
            open={openDelete}
            onCancel={handleCloseDelete}
            onConfirm={handleDelete}
        />
        <Snackbar
            open={alertOpen}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
        >
            <Alert
                onClose={handleCloseAlert}
                severity={alertSeverity}
                sx={{ width: '100%' }}
            >
                {alertMessage}
            </Alert>
        </Snackbar>
    </>)
};


export default TagsConfigs;
