import React, { useCallback, useState } from 'react'

import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
// import { Paper } from '@mui/material';
// import IconButton from '@mui/material/IconButton';
// // import Typography from '@mui/material/Typography';
// // import TextField from '@mui/material/TextField';
// import CloseIcon from '@mui/icons-material/Close';

import styles from "./styles.module.css";

// import apiQueries from "../../services/apiQueries";
// import client from "../../services/api";
// import { useMutation, useQuery } from "@apollo/client";
import {
    Autocomplete,
    TextField,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


const Input = ({ existedItems, loading, onAdd }) => {
    const [nameValue, setNameValue] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const handleChange = useCallback((e) => {
        const value = e.target.value;
        setNameValue(value);
        if (value === "") {
            setButtonDisabled(true);
        } else {
            setButtonDisabled(false);
        }
    }, [setNameValue, setButtonDisabled]);

    const handleAdd = useCallback(() => {
        onAdd({ name: nameValue });
    }, [onAdd, nameValue]);

    const handleAutompleteChange = useCallback((e, v) => {
        // console.log("handleAutompleteChange", v)
        setNameValue(v);
        if (v === "") {
            setButtonDisabled(true);
        } else {
            setButtonDisabled(false);
        }
    }, [setNameValue, setButtonDisabled]);



    return (
        <div
            className={styles['inputWrapper']}
        >
            <Autocomplete
                freeSolo
                disableClearable
                options={existedItems.map((option) => option.name)}
                fullWidth
                getOptionLabel={(option) => option}
                inputValue={nameValue}
                onChange={handleAutompleteChange}

                sx={{
                    marginLeft: '40px',
                    width: '300px',
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="New tag name"
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                        }}
                        onChange={handleChange}
                    />
                )}
            />
            <Button
                // size="small"
                disabled={buttonDisabled || loading}
                variant="outlined"
                onClick={handleAdd}
                size = "small"
                sx={{
                    marginLeft: 2,
                    minWidth: 100,
                }}
            >
                Add tag
            </Button>
            {loading ? 
                <Box
                    sx={{ 
                        padding: 1,
                        display: 'flex',
                    }}
                >
                    <CircularProgress
                        color="inherit"
                    />
                </Box>
            : null}
        </div>
    )
};


export default Input;
