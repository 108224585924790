import axios from "axios";


const createRequestProps = (props) => {
    return {
        method: props.method || axios.get,
        url: props.url,
        data: props.data || null,
        params: props.params || null,
    };
}

/* example */
// const requests = [
//   createRequestProps({ url: `/api/v1/partners/${partnerId}/playables/` }),
//   createRequestProps({ url: `/api/v1/partners/${partnerId}/playables/` }),
// ]

const cancelationErrorWrapper = (requestPromise) => {
    return new Promise((resolve, reject) =>  {
        requestPromise.then((...args) => {
            resolve(...args);
        })
        .catch(errors => {
            if (axios.isCancel(errors)) {
                console.warn('Request canceled', errors.message);
            } else {
                reject(errors);
            }
        })
    })
}

const multirequest = (requests, callback=() => {}) => {
    const cancelTokens = [];
    let sources;

    for (let i = 0; i < requests.length; i++) {
        cancelTokens.push(axios.CancelToken);
    }
    sources = cancelTokens.map(cancelToken => cancelToken.source());

    const axiosRequests = requests.map((request, index) => {
        const params = {
            cancelToken: sources[index].token,
            ...request.params,
        };

        let result;

        if (request.data) {
            // console.log('params', params);
            result = request.method(request.url, request.data, params)
        } else {
            result = request.method(request.url, params)
        }

        return result;
    });

    const requestsPromises = cancelationErrorWrapper(Promise.all(axiosRequests))
        requestsPromises.then(responses => {
            const errors = null;
            callback(errors, responses);
        })
        .catch(errors => {
            if (axios.isCancel(errors)) {
                console.warn('Request canceled', errors.message);
            } else {
                callback(errors);
            }
        })

    const cancelationFunction = () => {
        if (sources) {
            /* TODO sometimes it won't work */
            for (let source of sources) {
                source.cancel('Operation canceled by the user.');
            }
        }
    }

    return [requestsPromises, cancelationFunction]
};

const request = (request, callback=() => {}) => {
    const [requestsPromises, cancelation] = multirequest([request], (err, responses) => {
        if (err) {
            callback(err)
        } else {
            callback(null, responses[0])
        }
    });

    const promise = new Promise((resolve, reject) => {
        requestsPromises.then((responses) => {
            resolve(responses[0]);
        })
        .catch((e) => {
            reject(e);
        })
    });

    return [promise, cancelation];
};


const axiosHelpers = {
    createRequestProps,
    request,
    multirequest,
    cancelationErrorWrapper,
};


export default axiosHelpers;
