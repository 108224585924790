import React, { useCallback, useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';
import apiQueries from "../../services/apiQueries";
import client from "../../services/api";
import { useQuery } from "@apollo/client";

// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
import CategoriesSubcategoriesContainer from '../CategoriesSubcategoriesContainer';
import TagsInput from '../TagsInput';

import VideoFileUpload from '../VideoFileUpload';
// import VideoPasteUrl from '../VideoPasteUrl';

import styles from "./styles.module.css"
import ImagePasteUrl from '../ImagePasteUrl';
// import DebouncedTextField from '../DebouncedTextField';

import {
    useStoreFormRequired,
    useStoreFormOptional,
    useStoreFormFiles,
    useStoreFormLocation,
} from "./stateStore";
import DatePicker from '../DatePicker';
import { TextField } from '@mui/material';
import VideoThumbs from '../VideoThumbs';


function correctFormData(rawFormData) {
    // type CreateVideoInput {
    //     title: String!
    //     subtitle: String!
    //     categoryIds: [Float!]!
    //     tagsIds: [Float!]!
    //     subcategoryId: Float!
    //     url: String                  /* may be uploaded as file first, but now disable the just url options */
    //     sourceUrl: String!
    //     sourceLogoUrl: String!
    //     date: DateTime
    //     supersedesPreviousId: Float  /* relation to the previous videos */
    //     copyright: String
    //     newsScore: Float
    //     localityScore: Float
    //}

    const correctedFormData = {
        ...rawFormData,
        /* fix required fields */
        categoryIds: rawFormData.categoryIds.map(item => parseInt(item)),
        tagsIds: rawFormData.tagsIds.map(item => parseInt(item)),
        subcategoryId: parseInt(rawFormData.subcategoryId),
    };
    
    /* eliminate optional */
    if (correctedFormData.copyright === '') {
        delete correctedFormData.copyright;
    }

    if (correctedFormData.newsScore === '') {
        delete correctedFormData.newsScore;
    } else {
        correctedFormData.newsScore = parseFloat(correctedFormData.newsScore);
    }

    if (correctedFormData.localityScore === '') {
        delete correctedFormData.localityScore;
    } else {
        correctedFormData.localityScore = parseFloat(correctedFormData.localityScore);
    }

    if (correctedFormData.supersedesPreviousId === '') {
        delete correctedFormData.supersedesPreviousId;
    } else {
        correctedFormData.supersedesPreviousId = parseInt(correctedFormData.supersedesPreviousId);
    }

    if (correctedFormData.location === '') {
        delete correctedFormData.location;
    } else {
        // correctedFormData.supersedesPreviousId = parseInt(correctedFormData.supersedesPreviousId);
    }
    
    return correctedFormData; 
}


export default function FormAddVideo({ onSubmit, onSubmitError, handleClose, alertClose, itemData=null }) {
    // console.log('FormAddVideo', itemData);

    const _itemData = useMemo(() => {
        let ___itemData = null;
        if (itemData) {
            ___itemData = {...itemData};
            ___itemData.categoryIds = itemData.categories.map(item => item.id.toString());
            ___itemData.subcategoryId = itemData.subcategory ? itemData.subcategory.id.toString() : '';
            ___itemData.tagsIds = itemData.tags.map(item => item.id.toString());
            ___itemData.sourceUrl = itemData.source.sourceUrl;
            ___itemData.sourceLogoUrl = itemData.source.logoUrl;
            
            ___itemData.newsScore = itemData.newsScore ? itemData.newsScore.toString() : '';
            ___itemData.localityScore = itemData.localityScore ? itemData.localityScore.toString() : '';
            ___itemData.supersedesPreviousId = itemData.supersedesPreviousId ? itemData.supersedesPreviousId.toString() : '';
        }

        return ___itemData;
    }, [itemData]);
    
    const { /* loading, error, */ data:categoriesData, /* refetch */ } = useQuery(apiQueries.QUERY_CATEGORIES, {
        client,
        onError: (err) => {
            console.error(err);
        },
    });
    const sortedCategories = useMemo(() => {
        let list = [];
        if (categoriesData) {
            const listCopy = categoriesData.categories.map(item => item);
            list = listCopy.sort((a, b) => {
                return (a.id - b.id)
            });
        }

        return list;
    }, [categoriesData]);

    /* useStoreFormRequired */
    const setDefaultRequred = useStoreFormRequired(state => state.setDefault);

    const formDataRequired = useStoreFormRequired(state => state.formData);
    const isValidRequired = useStoreFormRequired(state => state.isValid);
    const setAllTouchedRequired = useStoreFormRequired(state => state.setAllTouched);
    const onTouchRequired = useStoreFormRequired(state => state.onTouch);
    const onChangeRequired = useStoreFormRequired(state => state.onChange);

    const titleError = useStoreFormRequired(state => state.errorsMessages.title);
    const subtitleError = useStoreFormRequired(state => state.errorsMessages.subtitle);
    // const categoryIdsError = useStoreFormRequired(state => state.errorsMessages.categoryIds);
    const subcategoryIdError = useStoreFormRequired(state => state.errorsMessages.subcategoryId);
    const tagsIdsError = useStoreFormRequired(state => state.errorsMessages.tagsIds);
    const sourceUrlError = useStoreFormRequired(state => state.errorsMessages.sourceUrl);
    const sourceLogoUrlError = useStoreFormRequired(state => state.sourceLogoUrlError);

    const titleValue = useStoreFormRequired(state => state.formData.title);
    const subtitleValue = useStoreFormRequired(state => state.formData.subtitle);
    const categoryIdsValue = useStoreFormRequired(state => state.formData.categoryIds);
    const subcategoryIdValue = useStoreFormRequired(state => state.formData.subcategoryId);
    const tagsIdsValue = useStoreFormRequired(state => state.formData.tagsIds);
    const sourceUrlValue = useStoreFormRequired(state => state.formData.sourceUrl);
    const sourceLogoUrlValue = useStoreFormRequired(state => state.formData.sourceLogoUrl);

    const titleTouched = useStoreFormRequired(state => state.touched.title);
    const subtitleTouched = useStoreFormRequired(state => state.touched.subtitle);
    // const categoryIdsTouched = useStoreFormRequired(state => state.touched.categoryIds);
    // const subcategoryIdTouched = useStoreFormRequired(state => state.touched.subcategoryId);
    // const tagsIdsTouched = useStoreFormRequired(state => state.touched.tagsIds);
    const sourceUrlTouched = useStoreFormRequired(state => state.touched.sourceUrl);
    const sourceLogoUrlTouched = useStoreFormRequired(state => state.touched.sourceLogoUrl);

    /* useStoreFormOptional */
    const setDefaultOptional = useStoreFormOptional(state => state.setDefault);

    const formDataOptional = useStoreFormOptional(state => state.formData);
    const isValidOptional = useStoreFormOptional(state => state.isValid);
    const setAllTouchedOptional = useStoreFormOptional(state => state.setAllTouched);
    const onTouchOptional = useStoreFormOptional(state => state.onTouch);
    const onChangeOptional = useStoreFormOptional(state => state.onChange);

    // const copyrightError = useStoreFormOptional(state => state.errorsMessages.copyright);
    const newsScoreError = useStoreFormOptional(state => state.errorsMessages.newsScore);
    const localityScoreError = useStoreFormOptional(state => state.errorsMessages.localityScore);
    const supersedesPreviousIdError = useStoreFormOptional(state => state.errorsMessages.supersedesPreviousId);
 
    const dateValue = useStoreFormOptional(state => state.formData.date);
    const copyrightValue = useStoreFormOptional(state => state.formData.copyright);
    const newsScoreValue = useStoreFormOptional(state => state.formData.newsScore);
    const localityScoreValue = useStoreFormOptional(state => state.formData.localityScore);
    const supersedesPreviousIdValue = useStoreFormOptional(state => state.formData.supersedesPreviousId);
    
    // const copyrightTouched = useStoreFormOptional(state => state.touched.copyright);
    const newsScoreTouched = useStoreFormOptional(state => state.touched.newsScore);
    const localityScoreTouched = useStoreFormOptional(state => state.touched.localityScore);
    const supersedesPreviousIdTouched = useStoreFormOptional(state => state.touched.supersedesPreviousId);
    
    
    /* useStoreFormLocation */
    const setDefaultLocation = useStoreFormLocation(state => state.setDefault);

    const formDataLocation = useStoreFormLocation(state => state.formData);
    const isValidLocation = useStoreFormLocation(state => state.isValid);
    const setAllTouchedLocation = useStoreFormLocation(state => state.setAllTouched);
    // const onTouchLocation = useStoreFormLocation(state => state.onTouch);
    const onChangeLocation = useStoreFormLocation(state => state.onChange);

    const latitudeError = useStoreFormLocation(state => state.errorsMessages.latitude);
    const longitudeError = useStoreFormLocation(state => state.errorsMessages.longitude);

    const latitudeValue = useStoreFormLocation(state => state.formData.latitude);
    const longitudeValue = useStoreFormLocation(state => state.formData.longitude);

    const latitudeTouched = useStoreFormLocation(state => state.touched.latitude);
    const longitudeTouched = useStoreFormLocation(state => state.touched.longitude);

    /* useStoreFormFiles */
    const setDefaultFiles = useStoreFormFiles(state => state.setDefault);

    const formDataFiles = useStoreFormFiles(state => state.formData);
    const isValidFiles = useStoreFormFiles(state => state.isValid);
    const setAllTouchedFiles = useStoreFormFiles(state => state.setAllTouched);
    // const onTouchFiles = useStoreFormFiles(state => state.onTouch);
    const onChangeFiles = useStoreFormFiles(state => state.onChange);
    const fieldTypeChange = useStoreFormFiles(state => state.fieldTypeChange);

    const urlError = useStoreFormFiles(state => state.errorsMessages.url);
    
    const urlValue = useStoreFormFiles(state => state.formData.url);
    
    const urlTouched = useStoreFormFiles(state => state.touched.url);

    const videoThumbsUrls = useMemo(() => {
        const urls = [];

        if (urlValue.type === "file" && urlValue.value) {
            urls.push(URL.createObjectURL(urlValue.value));
        } else if (urlValue.type === "url" && urlValue.value !== "") {
            urls.push(urlValue.value);
        }

        return urls;
    }, [urlValue]);


    const handleSelectVioeoFile = useCallback((value) => {
        fieldTypeChange(/* fieldName */ "url", /* fieldType */ "file");
        onChangeFiles(/* fieldName */ "url", value);
    }, [fieldTypeChange, onChangeFiles]);

    const handleSubmit = useCallback(event => {
        event.preventDefault();
        /* setAllTouched */
        setAllTouchedRequired();
        setAllTouchedOptional();
        setAllTouchedFiles();
        setAllTouchedLocation();

        // console.log("isValidRequired, isValidOptional, isValidFiles", isValidRequired, isValidOptional, isValidFiles);
        /* submit */
        if (isValidRequired && isValidOptional && isValidFiles && isValidLocation) {
            // (formData, optionalFormData, filesFormData) => {
            const location = formDataLocation.location;
            console.log("location", location);

            const correctedFormData = correctFormData({
                ...formDataRequired,
                ...formDataOptional,
                location,
            });
            // console.log("submit ", { formData: correctedFormData, formDataFiles });
            const id = _itemData ? _itemData.id : null;
            onSubmit({ formData: correctedFormData, formDataFiles, updateId:id });
        } else {
            onSubmitError();
        }
    }, [
        _itemData,

        onSubmit,
        onSubmitError,

        formDataRequired,
        formDataOptional,
        formDataFiles,
        formDataLocation,

        isValidRequired,
        isValidOptional,
        isValidFiles,
        isValidLocation,

        setAllTouchedRequired,
        setAllTouchedOptional,
        setAllTouchedFiles,
        setAllTouchedLocation,
    ]);

    // console.log("formDataRequired", formDataRequired);
    // console.log("formDataOptional", formDataOptional);
    /* listeners for alertClose */
    useEffect(() => {
        console.log("setDefault", _itemData);
        setDefaultRequred(_itemData);
        setDefaultOptional(_itemData);
        setDefaultFiles(_itemData);
        setDefaultLocation(_itemData);

        const unsub1 = useStoreFormRequired.subscribe(() => { alertClose() });
        const unsub2 = useStoreFormOptional.subscribe(() => { alertClose() });
        const unsub3 = useStoreFormFiles.subscribe(() => { alertClose() });
        const unsub4 = useStoreFormLocation.subscribe(() => { alertClose() });

        return () => {
            unsub1();
            unsub2();
            unsub3();
            unsub4();
        }
    }, [_itemData, alertClose, setDefaultRequred, setDefaultOptional, setDefaultFiles, setDefaultLocation]);


    return (
        <>
            <h4>Title<span className={styles["requiredField"]}></span></h4>
            {/* <DebouncedTextField */}
            <TextField
                value={titleValue}
                fullWidth
                label="Title"
                variant="outlined"
                sx={{
                    maxWidth: 700,
                }}

                error={
                    titleTouched && titleError
                        ? true
                        : false
                }
                onBlur={useCallback(() => onTouchRequired("title"), [onTouchRequired])}
                onChange={useCallback(e => onChangeRequired('title', e.target.value), [onChangeRequired])}
                helperText={titleTouched && titleError}
            />
            {/* <h4>Subtitles<span className={styles["requiredField"]}></span></h4> */}
            <h4>Subtitles</h4>
            {/* <DebouncedTextField */}
            <TextField
                value={subtitleValue}
                fullWidth
                id="outlined-basic"
                label="Subtitles"
                variant="outlined"
                sx={{
                    maxWidth: 700,
                }}

                error={
                    subtitleTouched && subtitleError
                        ? true
                        : false
                }
                onBlur={useCallback(() => onTouchRequired("subtitle"), [onTouchRequired])}
                onChange={useCallback(e => onChangeRequired('subtitle', e.target.value), [onChangeRequired])}
                helperText={subtitleTouched && subtitleError}
            />
            <h4>Category/Subcategory<span className={styles["requiredField"]}></span></h4>
            {subcategoryIdError ? <div className={styles["helperText"]}>{subcategoryIdError}</div> : null}
            <CategoriesSubcategoriesContainer
                categoryIdsValue={categoryIdsValue}
                subcategoryIdValue={subcategoryIdValue}
                categoriesData={sortedCategories}
                onChangeCategory={useCallback(value => {
                    onChangeRequired('categoryIds', value);
                }, [onChangeRequired])}
                onChangeSubcategory={useCallback(value => {
                    onChangeRequired('subcategoryId', value);
                }, [onChangeRequired])}
            />
            <h4>Tags<span className={styles["requiredField"]}></span></h4>
            {tagsIdsError ? <div className={styles["helperText"]}>{tagsIdsError}</div> : null}
            <TagsInput
                tagsIdsValue={tagsIdsValue}
                textFieldSx={{
                    maxWidth: 700,
                }}
                onChange={useCallback(value => {
                    onChangeRequired('tagsIds', value);
                }, [onChangeRequired])}
            />
            <h4>Video source<span className={styles["requiredField"]}></span></h4>
            {/* <RadioGroup
                row
                aria-label="gender"
                name="vide-file-row-radio-buttons-group"
                onChange={useCallback((event) => fieldTypeChange("url", event.target.value), [fieldTypeChange])}
                value={urlValue.type}
            >
                <FormControlLabel
                    value={"url"}
                    control={<Radio />}
                    label={"Insert URL"}
                    labelPlacement="end"
                />
                <FormControlLabel
                    value={"file"}
                    control={<Radio />}
                    label={"Upload File"}
                    labelPlacement="end"
                />
            </RadioGroup> */}
            {urlTouched && urlError ? <div className={styles["helperText"]}>{urlError}</div> : null}
            {/* {urlValue.type === "url" ?
                <VideoPasteUrl
                    value={urlValue.value}
                    onTouch={(e) => onTouchFiles('url')}
                    onChange={value => onChangeFiles("url", value)}
                />
                :
                <VideoFileUpload
                    onChange={value => onChangeFiles("url", value)}
                />
            } */}
            <div>{urlValue.type === "url" ? urlValue.value : null}</div>
            <VideoFileUpload
                    onChange={handleSelectVioeoFile}
            />
            <VideoThumbs
                previewUrls={videoThumbsUrls}
            />
            <h4>Source URL<span className={styles["requiredField"]}></span></h4>
            <TextField
                value={sourceUrlValue}
                fullWidth
                id="outlined-basic"
                label="Source URL"
                variant="outlined"
                sx={{
                    maxWidth: 700,
                }}

                error={
                    sourceUrlTouched && sourceUrlError
                        ? true
                        : false
                }
                onBlur={useCallback(() => onTouchRequired("sourceUrl"), [onTouchRequired])}
                onChange={useCallback(e => onChangeRequired('sourceUrl', e.target.value), [onChangeRequired])}
                helperText={sourceUrlTouched && sourceUrlError}
            />
            <h4>Source logo<span className={styles["requiredField"]}></span></h4>
            {/* "https://source.unsplash.com/random/200x200" */}
            {sourceLogoUrlTouched && sourceLogoUrlError ? <div className={styles["helperText"]}>{sourceLogoUrlError}</div> : null}
            <ImagePasteUrl
                label="Source logo URL"
                value={sourceLogoUrlValue}
                onBlur={useCallback(e => onTouchRequired('sourceLogoUrl', e.target.value), [onTouchRequired])}
                onChange={useCallback(value => onChangeRequired('sourceLogoUrl', value), [onChangeRequired])}
            />
            <h4>Copyright</h4>
            <TextField
                value={copyrightValue}
                fullWidth
                multiline
                label="Copyright"
                variant="outlined"
                sx={{
                    maxWidth: 700,
                }}
                onChange={useCallback(e => onChangeOptional('copyright', e.target.value), [onChangeOptional])}
            />
            <h4>Date</h4>
            <div>
            <DatePicker
                dateObject={dateValue}
                onChange={useCallback(value => onChangeOptional('date', value), [onChangeOptional])}
            />
            </div>
            <h4>News score</h4>
            <TextField
                // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                value={newsScoreValue}
                label="News score"
                variant="outlined"
                sx={{
                    maxWidth: 700,
                }}

                error={
                    newsScoreTouched && newsScoreError
                        ? true
                        : false
                }
                onBlur={useCallback(() => onTouchOptional("newsScore"), [onTouchOptional])}
                onChange={useCallback(e => onChangeOptional('newsScore', e.target.value), [onChangeOptional])}
                helperText={newsScoreTouched && newsScoreError} />
            <h4>Locality score</h4>
            <TextField
                // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                value={localityScoreValue}
                label="Locality score"
                variant="outlined"
                sx={{
                    maxWidth: 700,
                }}
                error={
                    localityScoreTouched && localityScoreError
                        ? true
                        : false
                }
                onBlur={useCallback(() => onTouchOptional("localityScore"), [onTouchOptional])}
                onChange={useCallback(e => onChangeOptional('localityScore', e.target.value), [onChangeOptional])}
                helperText={localityScoreTouched && localityScoreError}
            />
            <h4>Location</h4>
            <TextField
                // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                value={latitudeValue}
                label="Latitude"
                variant="outlined"
                sx={{
                    maxWidth: 700,
                    marginRight: "30px",
                }}
                error={
                    latitudeTouched && latitudeError
                        ? true
                        : false
                }
                onBlur={setAllTouchedLocation}
                onChange={useCallback(e => onChangeLocation('latitude', e.target.value), [onChangeLocation])}
                helperText={latitudeTouched && latitudeError}
            />
            <TextField
                // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                value={longitudeValue}
                label="Longitude"
                variant="outlined"
                sx={{
                    maxWidth: 700,
                }}
                error={
                    longitudeTouched && longitudeError
                        ? true
                        : false
                }
                onBlur={setAllTouchedLocation}
                onChange={useCallback(e => onChangeLocation('longitude', e.target.value), [onChangeLocation])}
                helperText={longitudeTouched && longitudeError}
            />
            <h4>Superseded by</h4>
            <TextField
                // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                value={supersedesPreviousIdValue}
                label="Superseded by (video ID)"
                variant="outlined"
                sx={{
                    maxWidth: 700,
                }}
                error={
                    supersedesPreviousIdTouched && supersedesPreviousIdError
                        ? true
                        : false
                }
                onBlur={useCallback(() => onTouchOptional("supersedesPreviousId"), [onTouchOptional])}
                onChange={useCallback(e => onChangeOptional('supersedesPreviousId', e.target.value), [onChangeOptional])}
                helperText={supersedesPreviousIdTouched && supersedesPreviousIdError}
            />
            <div
                className={styles['buttonsContainer']}
            >
                <Button
                    sx={{
                        margin: 5,
                    }}
                    size="large"
                    variant="contained"
                    color="inherit"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    sx={{
                        margin: 5,
                    }}
                    size="large"
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Save
                </Button>
            </div>
        </>
    );
}
