import React, { useCallback, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useMutation } from "@apollo/client";
import client from "../../services/api";
import apiQueries from "../../services/apiQueries";
import VideoCard from '../../components/VideoCard'


function VideoItemsList({
    onClickEdit,
    refreshFunc,

    categoryId,
    loadingRefetch,
    items,
    hasMore,
    error,
    fetchMore,
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElData, setAnchorElData] = useState({});

    const [deleteVideoItem, /* { deleteLoading, deleteError } */] = useMutation(apiQueries.MUTATION_DELETE_VIDEO, {
        client,
        onCompleted({ deleteVideo }) {
            if (deleteVideo) {
                setAnchorElData({});

                refreshFunc();
            }
        },
        onError: (e) => {
            refreshFunc();

            console.log("deleteVideoItem ERROR", e);
            console.log("deleteVideoItem ERROR", JSON.stringify(e, null, 2));
        }
    });

    const handleMenu = useCallback((event, data) => {
        // console.log("handleMenu", event, data)
        setAnchorEl(event.currentTarget);
        setAnchorElData(data);
    }, [setAnchorEl, setAnchorElData]);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
        setAnchorElData({});
    }, [setAnchorEl, setAnchorElData]);

    const handleDelete = useCallback(() => {
        handleClose();
        // console.log("id", anchorElData)
        deleteVideoItem({
            variables: { id: anchorElData.id },   
        });
    }, [anchorElData, handleClose, deleteVideoItem]);


    const _onClickEdit = useCallback(() => {
        handleClose();
        // console.log("anchorElData", anchorElData);
        onClickEdit(anchorElData);
    }, [anchorElData, onClickEdit, handleClose]);


    return (
        <>
            {loadingRefetch ?
                    "Loading..."
                :
                <InfiniteScroll
                    dataLength={items.length} //This is important field to render the next data
                    next={fetchMore}
                    hasMore={hasMore}
                    loader={<h4>Loading...</h4>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            {items.length === 0 ?
                                (<b>Yay! There is no items :(</b>)
                            :
                                (<b>Yay! You have seen it all</b>)
                            }
                        </p>
                    }
                    // below props only if you need pull down functionality
                    // refreshFunction={refreshFunc}
                    // pullDownToRefresh
                    // pullDownToRefreshThreshold={50}
                    // pullDownToRefreshContent={
                    //     <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                    // }
                    // releaseToRefreshContent={
                    //     <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                    // }
                >
                    <div className='list'>
                        {(error === null ?
                            (
                                items.map((item, index) => {
                                    return (
                                        <VideoCard
                                            key={item.id + 'id' + categoryId + "categoryId"}
                                            videoData={item}
                                            onMoreClick={handleMenu}
                                        />
                                    );
                                })
                            )
                        :
                            <>
                                <div>{JSON.stringify(error, null, 2)}</div>
                                {/* <div>statusCode: {error.networkError.statusCode}</div> here 3 */}
                                {/* <div>message: {error.networkError.result.errors.map(item => item.message)}</div> */}
                            </>
                        )}
                    </div>
                </InfiniteScroll>
            }
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={_onClickEdit}
                    sx={{
                        marginBottom: '25px',
                    }}
                >
                    Edit
                </MenuItem>
                <MenuItem
                    onClick={handleDelete}
                >
                    <span style={{ color: 'red' }}>Delete</span>
                </MenuItem>
            </Menu>
        </>
    )
}

export default VideoItemsList;
