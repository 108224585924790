import React, { useCallback, /* useEffect, */ useMemo } from 'react';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


function CategoriesSubcategoriesContainer({ categoryIdsValue, subcategoryIdValue, categoriesData, onChangeCategory, onChangeSubcategory }) {
    // console.log("CategoriesSubcategoriesContainer render");
    // useEffect(() => {
    //     console.log("CategoriesSubcategoriesContainer mount");
    // }, []);

    // useEffect(() => {
    //     // console.log("CategoriesSubcategoriesContainer categoriesData, onChangeCategory, onChangeSubcategory");
    // }, [categoriesData, onChangeCategory, onChangeSubcategory]);

    const handleChangeSubcategory = useCallback((e) => {
        const value = e.target.value;
        const ids = value.split("|")
        // console.log("ids", ids);
        onChangeCategory([ids[0]])
        onChangeSubcategory(ids[1])
    }, [onChangeCategory, onChangeSubcategory]);

    const _id = useMemo(() => {
        let id = ``;
        if (categoryIdsValue.length !== 0) {
            if (subcategoryIdValue !== "") {
                id = `${categoryIdsValue[0]}|${subcategoryIdValue}`;
            }
        }
        return id;
    }, [categoryIdsValue, subcategoryIdValue]);


    return (
        <div>
            <FormControl component="fieldset">
                <RadioGroup aria-label="gender" name="row-radio-buttons-group"
                    value={_id}
                >
                    {categoriesData.map((category, index) => {
                        return (
                            <div
                                key={'__category_subs' + category.id}
                            >
                                <FormLabel
                                    component="legend"
                                >
                                    {category.name}
                                </FormLabel>
                                {category.subcategories.map((item, index) => {
                                    return (
                                        <FormControlLabel
                                            key={'__subcategory' + item.id}
                                            value={`${category.id}|${item.id}`}
                                            control={<Radio />}
                                            label={item.name}
                                            labelPlacement="end"
                                            onChange={handleChangeSubcategory}
                                        />)

                                })}
                            </div>
                        )
                    })}
                </RadioGroup>
            </FormControl>
        </div>
    );
}


export default React.memo(CategoriesSubcategoriesContainer);
