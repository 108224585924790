import {
    ApolloClient,
    ApolloLink,
    InMemoryCache,
    // HttpLink,
    from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import auth from "./auth";
import { createUploadLink } from 'apollo-upload-client';

import apiQueries from './apiQueries';
import axiosHelpers from "./axiosHelpers";
import axios from "axios";


const SERVER_URI = process.env.REACT_APP_SERVER_URL;


const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    const token = auth.getApiToken();
    // console.log("$$$ token", token)
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            authorization: token ? ("Bearer " + token) : null,
        }
    }));

    return forward(operation);
})

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
        // console.log("graphQLErrors", graphQLErrors)
        for (let err of graphQLErrors) {
            // Apollo Server sets code to UNAUTHENTICATED
            // when an AuthenticationError is thrown in a resolver
            if (err.extensions.code === 'UNAUTHENTICATED') {
                console.log("!!!!! err.extensions.code === 'UNAUTHENTICATED'")
                auth.logout();
                // Modify the operation context with a new token
                // const oldHeaders = operation.getContext().headers;
                // operation.setContext({
                //     headers: {
                //         ...oldHeaders,
                //         authorization: getNewToken(),
                //     },
                // });
                // Retry the request, returning the new observable
                return forward(operation);
            } else if (err.extensions.exception.status === 401) {
                console.log("!!!!! err.extensions.code === 'UNAUTHORIZED'")
                auth.logout();
                return forward(operation);
            }
        }
    }

    // To retry on network errors, we recommend the RetryLink
    // instead of the onError link. This just logs the error.
    if (networkError) {
        // console.log(`[Network error]: ${networkError}`);
        // console.error("networkError", JSON.stringify(networkError, null, 2));
    }

    return forward(operation);
});


// If you provide a link chain to ApolloClient, you
// don't provide the `uri` option.
const client = new ApolloClient({
    // The `from` function combines an array of individual links
    // into a link chain
    link: from([
        authMiddleware,
        errorLink,
        // httpLink,
        createUploadLink({
            uri: SERVER_URI,
        }),
    ]),
    cache: new InMemoryCache(),
    // cache: "no-cache",
});


const loginMutation = (formData) => {
    return new Promise((resolve, reject) => {
        client
            .mutate({
                mutation: apiQueries.MUTATION_LOGIN,
                variables: {
                    email: formData.email,
                    password: formData.password,
                },
            })
            .then(result => {
                resolve(result);
            })
            .catch(result => {
                const graphQLErrors = result.graphQLErrors;
                console.error("graphQLErrors", graphQLErrors);

                if (graphQLErrors) {
                    for (let err of graphQLErrors) {
                        const code = err.extensions.exception.status;
                        console.log("error status", code);

                        if (code === 401) {
                            reject("Email or pasword is incorrect");
                        } else {
                            reject("Server error");
                        }

                        break;
                    }
                }
            });
    });
}


const createVideoMutation = (input) => {
    return new Promise((resolve, reject) => {
        client
            .mutate({
                mutation: apiQueries.MUTATION_CREATE_VIDEO,
                // mutation: apiQueries.MUTATION_UPLOAD_FILE,
                variables: {
                    input,
                }
            })
            .then(result => {
                resolve(result);
            })
            .catch(result => {
                reject(result);
            });
    });
};

const updateVideoMutation = (input) => {
    return new Promise((resolve, reject) => {
        client
            .mutate({
                mutation: apiQueries.MUTATION_UPDATE_VIDEO,
                variables: {
                    input,
                },
            })
            .then(result => {
                resolve(result);
            })
            .catch(result => {
                reject(result);
            });
    });
};

const uploadFile = (title, file, progressCB = () => {}) => {
    return new Promise((resolve, reject) => {
        client
            .mutate({
                mutation: apiQueries.MUTATION_CREATE_VIDEO_UPLOAD_URL,
                variables: {
                    videoTitle: title,
                },
            })
            .then(mutationResult => {
                console.log("mutationResult", mutationResult)
                const { uploadURL, videoURL } = mutationResult.data.createVideoUploadURL

                const params = {
                    headers: {
                        'Content-Type': "",  /* (file.type) it should be empty for jwPlayer s3 presigned link */
                        // 'Content-Length': file.length, /* it will be injected by the browser */
                    },
                    onUploadProgress: progressEvent => {
                        const progress = (progressEvent.loaded / progressEvent.total) * 100;
                        progressCB(progress);
                    }
                };
            
                const reqProps = axiosHelpers.createRequestProps({
                    method: axios.put,
                    url: uploadURL,
                    data: file,
                    params,
                });
            
                // console.log("title, file", title, file);
                // console.log("uploadURL, videoURL", uploadURL, videoURL);

                const [reqPromise, /* cancelation */] = axiosHelpers.request(reqProps);
                reqPromise.then(response => {
                        console.log(" uploadFile response", response);
                        resolve({ title, url: videoURL })
                    })
                    .catch(error => {
                        console.error('upload file key', title, error);
                        console.error(JSON.stringify(error, null, 2));
                        reject(error);
                    })

            })
            .catch(error => {
                console.error('MUTATION_CREATE_UPLOAD_FILE_URL', title, error);
                console.error(JSON.stringify(error, null, 2));
                reject(error);
            });
    })
};


export default client;

export {
    uploadFile,
    loginMutation,
    createVideoMutation,
    updateVideoMutation,
};
