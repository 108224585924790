import React, { useCallback, useEffect } from 'react';
import Button from '@mui/material/Button';
import styles from "./styles.module.css"
// import DebouncedTextField from '../DebouncedTextField';
import { TextField } from '@mui/material';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useStoreFormRequired } from './stateStore';


export default function FormAddUser({ onSubmit, onSubmitError, handleClose, alertClose, clearFormCounter }) {
    /* useStoreFormRequired */
    const setDefaultRequred = useStoreFormRequired(state => state.setDefault);

    const formDataRequired = useStoreFormRequired(state => state.formData);
    const isValidRequired = useStoreFormRequired(state => state.isValid);
    const setAllTouchedRequired = useStoreFormRequired(state => state.setAllTouched);
    const onTouchRequired = useStoreFormRequired(state => state.onTouch);
    const onChangeRequired = useStoreFormRequired(state => state.onChange);

    const emailError = useStoreFormRequired(state => state.errorsMessages.email);
    const passwordError = useStoreFormRequired(state => state.errorsMessages.password);
    const passwordConfirmError = useStoreFormRequired(state => state.errorsMessages.passwordConfirm);
    // const roleError = useStoreFormRequired(state => state.errorsMessages.role);

    const emailValue = useStoreFormRequired(state => state.formData.email);
    const passwordValue = useStoreFormRequired(state => state.formData.password);
    const passwordConfirmValue = useStoreFormRequired(state => state.formData.passwordConfirm);
    const roleValue = useStoreFormRequired(state => state.formData.role);


    const emailTouched = useStoreFormRequired(state => state.touched.email);
    const passwordTouched = useStoreFormRequired(state => state.touched.password);
    const passwordConfirmTouched = useStoreFormRequired(state => state.touched.passwordConfirm);
    // const roleTouched = useStoreFormRequired(state => state.touched.role);

    const handleSubmit = useCallback(event => {
        event.preventDefault();
        /* setAllTouched */
        setAllTouchedRequired();

        /* submit */
        if (isValidRequired) {
            const formData = {
                ...formDataRequired,

                // enum Provider {
                //     anonymous
                //     email
                //     google
                //     apple
                //     facebook
                // }
                provider: "email",
                roles: ["user"],
            };

            if (formData.role === 'admin') {
                formData.roles = ["user", "admin"];
            } else if (formData.role === 'editor') {
                formData.roles = ["user", "editor"];
            }

            // const role = formDataRequired.role
            delete formData.passwordConfirm;
            delete formData.role;

            // console.log("submit ", { formData, role });
            console.log("submit ", { formData });
            onSubmit({ formData });
        } else {
            onSubmitError();
        }
    }, [
        isValidRequired,
        formDataRequired,
        setAllTouchedRequired,
        onSubmit,
        onSubmitError,
    ]);

    console.log("FormAddUser");

    /* listeners for alertClose */
    useEffect(() => {
        setDefaultRequred();

        const unsub1 = useStoreFormRequired.subscribe(() => { 
            console.log("alertClose");
            alertClose() });

        return () => {
            unsub1();
        }
    }, [alertClose, setDefaultRequred, clearFormCounter]);
    useEffect(() => {
        console.log("alertClose mount");
    }, []);


    return (
        <>
            <h4>Email</h4>
            <TextField
                error={
                    emailTouched && emailError
                        ? true
                        : false
                }
                // multiline
                fullWidth
                autoComplete="new-password"
                label="Email"
                variant="outlined"
                sx={{
                    maxWidth: 370,
                }}
                type="text"
                value={emailValue}
                onChange={useCallback((e) => onChangeRequired("email", e.target.value), [onChangeRequired])}
                onBlur={useCallback(() => onTouchRequired("email"), [onTouchRequired])}
                helperText={emailTouched && emailError}
            />
            <h4>Password</h4>
            <TextField
                error={
                    passwordTouched && passwordError
                        ? true
                        : false
                }
                // multiline
                fullWidth
                autoComplete="new-password"
                label="Password"
                variant="outlined"
                sx={{
                    maxWidth: 370,
                }}
                type="password"
                value={passwordValue}

                onChange={useCallback((e) => onChangeRequired("password", e.target.value), [onChangeRequired])}
                onBlur={useCallback(() => onTouchRequired("password"), [onTouchRequired])}
                helperText={passwordTouched && passwordError}
            />
            <h4>Confirm password</h4>
            <TextField
                error={
                    passwordConfirmTouched && passwordConfirmError
                        ? true
                        : false
                }
                // multiline
                fullWidth
                autoComplete="new-password"
                label="Confirm password"
                variant="outlined"
                sx={{
                    maxWidth: 370,
                }}

                type="password"
                value={passwordConfirmValue}

                onChange={useCallback((e) => onChangeRequired("passwordConfirm", e.target.value), [onChangeRequired])}
                onBlur={useCallback(() => onTouchRequired("passwordConfirm"), [onTouchRequired])}
                helperText={passwordConfirmTouched && passwordConfirmError}
            />
            <h4>Role</h4>
            <RadioGroup
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: 'center',
                }}
                row
                aria-label="gender"
                name="vide-file-row-radio-buttons-group"
                onChange={useCallback((event) => onChangeRequired("role", event.target.value), [onChangeRequired])}
                value={roleValue}
            >
                <FormControlLabel
                    value={"admin"}
                    control={<Radio />}
                    label={"admin"}
                    labelPlacement="end"
                />
                <FormControlLabel
                    value={"editor"}
                    control={<Radio />}
                    label={"editor"}
                    labelPlacement="end"
                />
                <FormControlLabel
                    value={"user"}
                    control={<Radio />}
                    label={"user"}
                    labelPlacement="end"
                />
            </RadioGroup>
            <div
                className={styles['buttonsContainer']}
            >
                <Button
                    sx={{
                        margin: 5,
                    }}
                    size="large"
                    variant="contained"
                    color="inherit"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    sx={{
                        margin: 5,
                    }}
                    size="large"
                    color="primary"
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Create
                </Button>
            </div>
        </>
    );
}
