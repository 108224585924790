import React, { useCallback, useEffect, /* useEffect, */ useMemo, useState } from 'react'
import apiQueries from "../../services/apiQueries";
import client from "../../services/api";
import { useQuery } from "@apollo/client";

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


function CategoriesItems({ setCategoryId }) {
    const { /* loading, error, */ data, /* refetch */ } = useQuery(apiQueries.QUERY_CATEGORIES, {
        client,
        fetchPolicy: 'no-cache',
    });
    // console.log("{ loading, error, data, }", { /* loading, error, */ data, });
    const [alignment, setAlignment] = useState(1);

    const sortedCategories = useMemo(() => {
        let list = [];
        if (data) {
            const listCopy = data.categories.map(item => item);
            list = listCopy.sort((a, b) => {
                return (a.id - b.id)
            });
        }

        return list;
    }, [data]);

    useEffect(() => {
        if (sortedCategories.length > 0) {
            setCategoryId(sortedCategories[0].id);
        }
    }, [sortedCategories, setCategoryId]);

    const handleChange = useCallback((event, newAlignment) => {
        if (newAlignment !== null) {
            if (newAlignment !== alignment) {
                setAlignment(newAlignment);
                setCategoryId(newAlignment);
            }
        }
    }, [alignment, setAlignment, setCategoryId]);


    return (
        <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleChange}
        >
            {sortedCategories.map((category, index) => {
                return (
                    <ToggleButton
                        key={category.id + "category"}
                        value={category.id}
                    >
                        {category.name}
                    </ToggleButton>)
            })}
        </ToggleButtonGroup>
    );
};

export default CategoriesItems;
