import create from 'zustand'
import formValidator from "../../utils/formValidator";
import produce from 'immer'


/* validation form */
const requiredFieldsSchema = {
    title: {
        rules: ["required",],
        customMessage: "Title is required"
    },
    // subtitle: {
    //     rules: ["required"],
    //     customMessage: "Subtitle is required"
    // },


    categoryIds: {
        rules: ["arrayLength:1:1000"],
    },
    subcategoryId: {
        rules: ["required"],
        customMessage: "Please select subcategory"
    },
    tagsIds: {
        rules: ["arrayLength:1:1000"],
    },

    sourceUrl: {
        rules: ["required"],
    },
    sourceLogoUrl: {
        rules: ["required"],
    },
};

const optionalFieldsSchema = {
    newsScore: {
        rules: ["isNumber"],
    },
    localityScore: {
        rules: ["isNumber"],
    },
    supersedesPreviousId: {
        rules: ["isId"],
    },
};

const locationFieldsSchema = {
    latitude: {
        rules: ["isLatitude"],
    },
    longitude: {
        rules: ["isLongitude"],
    },
};

const fieldsFactory = {
    create(attrs) {
        return {
            title: "",
            subtitle: "",

            categoryIds: [],
            subcategoryId: "",
            tagsIds: [],
            sourceUrl: "",
            sourceLogoUrl: "",

            ...attrs
        };
    },
};

const optionalFieldsFactory = {
    create(attrs) {
        return {
            copyright: "",
            newsScore: "",
            localityScore: "",
            supersedesPreviousId: "",
            date: new Date(),

            ...attrs
        };
    },
};

const locationFieldsFactory = {
    create(attrs) {
        return {
            location: "",
            latitude: "",
            longitude: "",

            ...attrs
        };
    },
};

const validateOptionalFields = (formData, fieldsSchema) => {
    const { errorsMessages } = formValidator.isValid(
        formData,
        fieldsSchema,
    );

    const _errorsMessages = { ...errorsMessages };

    for (let key in _errorsMessages) {
        if (formData[key] === '') {
            delete _errorsMessages[key];
        }
    }

    const _isValid = Object.keys(_errorsMessages).length === 0;

    return {
        isValid: _isValid,
        errorsMessages: _errorsMessages,
    };
}

const validateLocationFields = (formData, fieldsSchema) => {
    let fieldsClear = true;

    const _formData = formData;
    delete _formData.location;

    for (let key in _formData) {
        if (_formData[key] !== '') {
            fieldsClear = false;
            break;
        }
    }

    const { isValid, errorsMessages } = formValidator.isValid(
        formData,
        fieldsSchema,
    );

    let _isValid = isValid;
    let _errorsMessages = { ...errorsMessages };

    if (fieldsClear) {
        _errorsMessages = {};
        _isValid = true;
    }

    return {
        isValid: _isValid,
        errorsMessages: _errorsMessages,
    };
}

/* fix for files non consistent with above !!! :(( */
const filesFormFactory = {
    create(attrs) {
        return {
            url: {
                // type: "url", /* file or url */
                type: "file", /* file or url */
                value: null,
            },

            ...attrs
        };
    },
};

const validateFilesFields = (formData) => {
    const errorsMessages = {};

    for (let key in formData) {
        if (formData[key].type === 'url') {
            errorsMessages[key] = formData[key].value === "" ? "URL is required" : null;
        } else {
            errorsMessages[key] = formData[key].value === null ? "File is required" : null;
        }
    }

    const _isValid = Object.keys(errorsMessages).every(key => {
        return !!errorsMessages[key] === false;
    });

    return {
        isValid: _isValid,
        errorsMessages: errorsMessages,
    };
}

/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
function selectObjectValues(dataItem, neededKeys) {
    const data = {}
    
    neededKeys.forEach((key)=> {
        if (dataItem[key] === 0 || !!dataItem[key]) {
            data[key] = dataItem[key];
        }
    });

    return data;
}

function setAllKeysTo(someobject, value) {
    for (let key in someobject) {
        someobject[key] = value;
    }
}

function setAllTouchedFactory(set) {
    return (isTouched=true) => {
        set(produce(state => {
            setAllKeysTo(state.touched, isTouched);
        }))
    }
}


const defaultFilesFormData = filesFormFactory.create();
const defaultFilesTouchedFields = filesFormFactory.create();
setAllKeysTo(defaultFilesTouchedFields, false);
const _defaultFilesErrorsMessages = filesFormFactory.create()
setAllKeysTo(_defaultFilesErrorsMessages, '');
const defaultFilesErrorsMessages = {
    ..._defaultFilesErrorsMessages,
    ...validateFilesFields(defaultFilesFormData).errorsMessages,
};

const useStoreFormFiles = create((set, get) => ({
    isValid: false,
    touched: defaultFilesTouchedFields,
    errorsMessages: defaultFilesErrorsMessages,
    formData: defaultFilesFormData,

    setDefault: (itemData) => {
        let selectedValues = {}
        if (itemData) {
            const keys = Object.keys(filesFormFactory.create());
            const _selectedValues = selectObjectValues(itemData, keys);
            Object.keys(_selectedValues).forEach(key => { /* its tricky but sorry :( */
                selectedValues[key] = {
                    type: "url", /* file or url */
                    value: _selectedValues[key],
                }
            });
        }

        const defaultFilesFormData = filesFormFactory.create(selectedValues);
        const _defaultFilesErrorsMessages = filesFormFactory.create()
        setAllKeysTo(_defaultFilesErrorsMessages, '');
        const { isValid, errorsMessages } = validateFilesFields(defaultFilesFormData);
        const defaultFilesErrorsMessages = {
            ..._defaultFilesErrorsMessages,
            ...errorsMessages,
        };        

        set({
            isValid,
            touched: defaultFilesTouchedFields,
            errorsMessages: defaultFilesErrorsMessages,
            formData: filesFormFactory.create(selectedValues),
        });
    },
    fieldTypeChange: (fieldName, type) => {
        const filesFormData = get().formData;
        const newValue = type === 'url' ? "" : null;
        const newFilesFormData = {
            ...filesFormData,
            [fieldName]: {
                ...filesFormData[fieldName],
                type,
                value: newValue,
            },
        };

        const { isValid, errorsMessages } = validateFilesFields(newFilesFormData);

        set(produce(state => {
            state.formData[fieldName].type = type;
            state.formData[fieldName].value = newValue;

            for (let key in errorsMessages) {
                state.errorsMessages[key] = errorsMessages[key];
            }
            
            state.isValid = isValid;
            state.touched[fieldName] = true;
        }));
    },

    setAllTouched: setAllTouchedFactory(set),

    onTouch: (fieldName) => {
        const { isValid, errorsMessages } = validateFilesFields(
            get().formData,
        );

        set(produce(state => {
            for (let key in errorsMessages) {
                state.errorsMessages[key] = errorsMessages[key];
            }

            state.isValid = isValid;
            state.touched[fieldName] = true;
        }));
    },
    onChange: (fieldName, value) => {
        const newFormData = produce(state => {
            state[fieldName].value = value;
        })(get().formData);

        const { isValid, errorsMessages } = validateFilesFields(
            newFormData,
        );

        set(produce(state => {
            state.formData[fieldName].value = value;
            state.errorsMessages[fieldName] = errorsMessages[fieldName];
            state.isValid = isValid;
            state.touched[fieldName] = true;
        }));
    },
}));


const defaultOptionalFormData = optionalFieldsFactory.create();
const defaultOptionalTouchedFields = optionalFieldsFactory.create();
setAllKeysTo(defaultOptionalTouchedFields, false);
const _defaultOptionalErrorsMessages = optionalFieldsFactory.create()
setAllKeysTo(_defaultOptionalErrorsMessages, '');
const defaultOptionalErrorsMessages = {
    ..._defaultOptionalErrorsMessages,
    ...validateOptionalFields(defaultOptionalFormData, optionalFieldsSchema).errorsMessages,
};

const useStoreFormOptional = create((set, get) => ({
    isValid: true,
    touched: defaultOptionalTouchedFields,
    errorsMessages: defaultOptionalErrorsMessages,
    formData: defaultOptionalFormData,

    setDefault: (itemData) => {
        let selectedValues = {}
        if (itemData) {
            const keys = Object.keys(optionalFieldsFactory.create());
            selectedValues = selectObjectValues(itemData, keys);
        }

        if (selectedValues.date) {
            selectedValues.date = new Date(selectedValues.date);
        }

        const defaultOptionalFormData = optionalFieldsFactory.create(selectedValues);
        const _defaultOptionalErrorsMessages = optionalFieldsFactory.create()
        setAllKeysTo(_defaultOptionalErrorsMessages, '');
        const { isValid, errorsMessages } = validateOptionalFields(
            defaultOptionalFormData,
            optionalFieldsSchema
        );
        const defaultOptionalErrorsMessages = {
            ..._defaultOptionalErrorsMessages,
            ...errorsMessages,
        };

        set({
            isValid,
            touched: defaultOptionalTouchedFields,
            errorsMessages: defaultOptionalErrorsMessages,
            formData: defaultOptionalFormData,
        });
    },
    setAllTouched: setAllTouchedFactory(set),
    onTouch: (fieldName) => {   
        const { isValid, errorsMessages } = validateOptionalFields(
            get().formData,
            optionalFieldsSchema,
        );

        set(produce(state => {
            for (let key in errorsMessages) {
                state.errorsMessages[key] = errorsMessages[key];
            }

            state.isValid = isValid;
            state.touched[fieldName] = true;
        }));
    },
    onChange: (fieldName, value) => {
        const newFormData = {
            ...get().formData,
            [fieldName]: value,
        };

        const { isValid, errorsMessages } = validateOptionalFields(
            newFormData,
            optionalFieldsSchema,
        );

        set(produce(state => {
            state.formData[fieldName] = value;
            state.errorsMessages[fieldName] = errorsMessages[fieldName];
            state.isValid = isValid;
            state.touched[fieldName] = true;
        }));
    },
}));


const defaultFormData = fieldsFactory.create();
const defaultTouchedFields = fieldsFactory.create();
setAllKeysTo(defaultTouchedFields, false);
const _defaultErrorsMessages = fieldsFactory.create()
setAllKeysTo(_defaultErrorsMessages, '');
const defaultErrorsMessages = {
    ..._defaultErrorsMessages,
    ...formValidator.isValid(defaultFormData, requiredFieldsSchema).errorsMessages,
};

const useStoreFormRequired = create((set, get) => ({
    isValid: false,
    touched: defaultTouchedFields,
    errorsMessages: defaultErrorsMessages,
    formData: fieldsFactory.create(),

    setDefault: (itemData) => {
        let selectedValues = {}
        if (itemData) {
            const keys = Object.keys(fieldsFactory.create());
            selectedValues = selectObjectValues(itemData, keys);
        }

        const defaultFormData = fieldsFactory.create(selectedValues);
        const _defaultErrorsMessages = fieldsFactory.create()
        setAllKeysTo(_defaultErrorsMessages, '');
        const { isValid, errorsMessages } = formValidator.isValid(defaultFormData, requiredFieldsSchema);
        const defaultErrorsMessages = {
            ..._defaultErrorsMessages,
            ...errorsMessages,
        };


        set({
            isValid,
            touched: defaultTouchedFields,
            errorsMessages: defaultErrorsMessages,
            formData: defaultFormData,
        });
    },
    setAllTouched: setAllTouchedFactory(set),
    onTouch: (fieldName) => {   
        const { isValid, errorsMessages } = formValidator.isValid(
            get().formData,
            requiredFieldsSchema,
        );

        set(produce(state => {
            for (let key in errorsMessages) {
                state.errorsMessages[key] = errorsMessages[key];
            }

            state.isValid = isValid;
            state.touched[fieldName] = true;
        }));
    },
    onChange: (fieldName, value) => {
        const newFormData = {
            ...get().formData,
            [fieldName]: value,
        };

        const { isValid, errorsMessages } = formValidator.isValid(
            newFormData,
            requiredFieldsSchema,
        );

        set(produce(state => {
            state.formData[fieldName] = value;
            state.errorsMessages[fieldName] = errorsMessages[fieldName];
            state.isValid = isValid;
            state.touched[fieldName] = true;
        }));
    },
}))


/* location optional */
const defaultLocationFormData = locationFieldsFactory.create();
const defaultLocationTouchedFields = locationFieldsFactory.create();
setAllKeysTo(defaultLocationTouchedFields, false);
const _defaultLocationErrorsMessages = locationFieldsFactory.create()
setAllKeysTo(_defaultLocationErrorsMessages, '');
const defaultLocationErrorsMessages = {
    ..._defaultLocationErrorsMessages,
    ...validateLocationFields(defaultLocationFormData, locationFieldsSchema).errorsMessages,
};

const useStoreFormLocation = create((set, get) => ({
    isValid: false,
    touched: defaultLocationTouchedFields,
    errorsMessages: defaultLocationErrorsMessages,
    formData: locationFieldsFactory.create(),

    setDefault: (itemData) => {
        let selectedValues = {};
        if (itemData) {
            if (itemData.location) {
                const [latitude, longitude] = itemData.location.split(",");
                selectedValues = {
                    location: itemData.location,
                    latitude: latitude || "", 
                    longitude: longitude || "", 
                };
            }
        }

        const defaultLocationFormData = locationFieldsFactory.create(selectedValues);
        const _defaultLocationErrorsMessages = locationFieldsFactory.create()
        setAllKeysTo(_defaultLocationErrorsMessages, '');
        const { isValid, errorsMessages } = validateLocationFields(defaultLocationFormData, locationFieldsSchema);
        const defaultLocationErrorsMessages = {
            ..._defaultLocationErrorsMessages,
            ...errorsMessages,
        };


        set({
            isValid,
            touched: defaultLocationTouchedFields,
            errorsMessages: defaultLocationErrorsMessages,
            formData: defaultLocationFormData,
        });
    },
    setAllTouched: setAllTouchedFactory(set),
    onTouch: (fieldName) => {   
        const { isValid, errorsMessages } = validateLocationFields(
            get().formData,
            locationFieldsSchema,
        );

        set(produce(state => {
            for (let key in errorsMessages) {
                state.errorsMessages[key] = errorsMessages[key];
            }

            state.isValid = isValid;
            state.touched[fieldName] = true;
        }));
    },
    onChange: (fieldName, value) => {
        const newFormData = {
            ...get().formData,
            [fieldName]: value,
        };

        const { isValid, errorsMessages } = validateLocationFields(
            newFormData,
            locationFieldsSchema,
        );

        let location = '';
        if (isValid) {
            location = `${newFormData.latitude},${newFormData.longitude}` 
        }

        set(produce(state => {
            state.formData.location = location;
            
            state.formData[fieldName] = value;
            // state.errorsMessages[fieldName] = errorsMessages[fieldName];
            state.errorsMessages = errorsMessages;
            state.isValid = isValid;
            // state.touched[fieldName] = true;
            setAllKeysTo(state.touched, true);
        }));
    },
}))


export {
    useStoreFormRequired,
    useStoreFormOptional,
    useStoreFormFiles,
    useStoreFormLocation,
};
