import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route, 
  Redirect,
  Link,
  useHistory,
} from 'react-router-dom';

import React, { useState, useCallback } from "react";

import {
  Tabs,
  Tab,
} from "@mui/material";

import AppBar from '@mui/material/AppBar';
import styles from './App.module.css';

import LoginScreen from './screens/LoginScreen';
import VideoScreen from './screens/VideoScreen';
import UsersScreen from './screens/UsersScreen';
import CategoriesScreen from './screens/CategoriesScreen';

import { WrapperComponent } from './components/WrapperComponent/WrapperComponent';
import auth from "./services/auth";

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';

import useLoggedUserStore from "./state-store/useLoggedUserStore";
import { useEffect } from 'react';
import parseJwt from './utils/parseJwt';
import {
  LOGIN_ROUTE,
  DBOARD_ROUTE,
  DBOARD_USERS_ROUTE,
  DBOARD_VIDEOS_ROUTE,
  DBOARD_CATEGORIES_ROUTE,
  HOME_ROUTE,
} from "./routes";
import { useMemo } from 'react';


function isAuthenticated() {
  const apiToken = auth.getApiToken();
  const authenticated = apiToken ? true : false;

  return authenticated;
}


function NotAuthenticatedRedirect({ authenticated }) {
  return (
    <>
    {authenticated ?
      null
      :
      <Redirect to={LOGIN_ROUTE} />
    }
    </>
  );
}

function NoMatch() {
  const [authenticated, setAuthenticated] = useState(isAuthenticated());
  useEffect(() => {
    const authCallback = ({ apiJWT }) => {
      setAuthenticated(apiJWT ? true : false);
    };
    auth.on("change", authCallback);
    return () => {
      auth.off("change", authCallback);
    };
  }, [setAuthenticated]);


  return (
    <>
    {authenticated ?
      <>
        {/* {admin ? <Redirect to="/admin-dashboard/home" /> : <Redirect to="/dashboard/home" />} */}
        <Redirect to={HOME_ROUTE} />
      </>
      :
      <Redirect to={LOGIN_ROUTE} />}
      {/* <h3>
         No match for <code>{location.pathname}</code>
      </h3> */}
    </>
  );
}

// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
function RouteWithSubRoutes(routeProps) {
  return (
    <Route
      path={routeProps.path}
      render={props => (
        // pass the sub-routes down to keep nesting
        <routeProps.component {...props} routes={routeProps.routes}/>
      )}
    />
  );
}

// Some folks find value in a centralized route config.
// A route config is just data. React is great at mapping
// data into components, and <Route> is a component.

// Our route config is just an array of logical "routes"
// with `path` and `component` props, ordered the same
// way you'd do inside a `<Switch>`.


const routesEditorDashoard = [
  {
    path: DBOARD_VIDEOS_ROUTE,
    pathLable: 'Videos',
    component: VideoScreen,
  },
  {
    path: DBOARD_CATEGORIES_ROUTE,
    pathLable: 'Categories',
    component: CategoriesScreen,
  },
];

const routesAdminDashoard = [
  {
    path: DBOARD_USERS_ROUTE,
    pathLable: 'Users',
    component: UsersScreen,
  },
  ...routesEditorDashoard,
];



function getRoutes(roles=[]) {
  let routesDashboard = [];
  
  if (roles.includes("admin")) {
    routesDashboard = [
      ...routesAdminDashoard,
    ];
  } else if (roles.includes("editor")) {
    routesDashboard = [
      ...routesEditorDashoard,
    ];
  }

  const routes = [
    {
      path: LOGIN_ROUTE,
      pathLable: '',
      component: LoginScreen,
    },
    {
      path: DBOARD_ROUTE,
      pathLable: '',
      component: Dashboard,
      routes: routesDashboard,
    },
  ];

  return routes;
}


function Dashboard({ routes, location }) {
  const history = useHistory();
  const email = useLoggedUserStore(state => state.email);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, [setAnchorEl]);


  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleLogout = useCallback(() => {
    handleClose();
    /* clear auth data */
    auth.logout();
    /* and redirect after */
    history.push(LOGIN_ROUTE);
  }, [handleClose, history]);


  return (<>
    <AppBar
      position="static"
    >
      <div className={styles.navWrapper2}>
        <div className={styles.navWrapper}>
          <div className={styles.navTabsWrapper}>
              <Tabs
                // scrollButtons="on"
                indicatorColor="secondary"
                textColor="inherit"
                value={location.pathname}
              >
                {routes.map((route, i) => (<Tab
                  key={`key-${route.path}`}
                  id={"tab" + i}
                  className={styles.tab}
                  wrapped={true}
                  label={route.pathLable}
                  value={route.path}
                  component={Link}
                  to={route.path}
                />))
                }
              </Tabs>
          </div>
          <div className={styles.userInfoWrapper}>
            <Typography 
              // variant="h1" component="h2"
              sx={{
                margin: 'auto',
              }}
            >
              {email}
            </Typography>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              sx={{
                marginLeft: "auto",
              }}
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
              <MenuItem onClick={handleLogout}>
                Logout
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </AppBar>
    <WrapperComponent>
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i + 'secondaryRoute'} {...route} />
        ))}

        <Route path='*'>
          <NoMatch/>
        </Route>
      </Switch>
    </WrapperComponent>
  </>)
}


function App() {
  const [authenticated, setAuthenticated] = useState(isAuthenticated());
  const tokenData = useLoggedUserStore(state => state.tokenData);
  const setTokenData = useLoggedUserStore(state => state.setTokenData);

  useEffect(() => {
    const apiJWT = auth.getApiToken();
    if (apiJWT) {
      const parsedToken = parseJwt(apiJWT);
      // console.log("parsedToken", parsedToken);
      setTokenData(parsedToken);
    } else {
      setTokenData();
    }

    const authCallback = ({ apiJWT }) => {
      setAuthenticated(apiJWT ? true : false);
      
      if (apiJWT) {
        const parsedToken = parseJwt(apiJWT);
        setTokenData(parsedToken);
      } else {
        setTokenData();
      }
    };
    auth.on("change", authCallback);
    return () => {
      auth.off("change", authCallback);
    };
  }, [setAuthenticated, setTokenData]);

  const _routes = useMemo(() => {
    let routes = getRoutes();
    if (tokenData.roles) {
      routes = getRoutes(tokenData.roles);
    }

    return routes;
  }, [tokenData.roles]);


  return (
    <Router>
      <Route
        path="/"
        render={(/* { location } */) => {
          return (
            <>
              <NotAuthenticatedRedirect
                authenticated={authenticated}
              />
              <Switch>
                {_routes.map((route, i) => (
                  <RouteWithSubRoutes key={i + '_routes_key'} {...route} />
                ))}
                <Route path='*'>
                  <NoMatch/>
                </Route>
              </Switch>
            </>
          )
        }}
      />
    </Router>
  );
}


export default App;
