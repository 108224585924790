import createStore from "./storeGenerator";

const useStoreVideo = createStore();
const useStoreSearchVideo = createStore();
// useStoreSearchVideo.subscribe((state) => { console.log("useStoreSearchVideo", state) })

export {
    useStoreVideo,
    useStoreSearchVideo,
}
