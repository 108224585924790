import create from 'zustand'
import auth from '../services/auth';
import parseJwt from '../utils/parseJwt';


function tokenDataToState(tokenData={}) {
    return {
        tokenData: tokenData || {},
        email: tokenData ? tokenData.email : '',
    };
}

const apiJWT = auth.getApiToken();
let parsedToken = {};
if (apiJWT) {
  parsedToken = parseJwt(apiJWT);
}

const useStore = create(set => ({
    email: tokenDataToState(parsedToken).email,
    tokenData: tokenDataToState(parsedToken).tokenData,
    setTokenData: (tokenData = {}) => set(state => {
        return tokenDataToState(tokenData);
    }),
}))


export default useStore;
