import React, { useCallback, useMemo } from 'react'
import ReactPlayer from 'react-player'
import './styles.css'
// import VideoTags from '../VideoTags'
// import SettingsIcon from '@mui/icons-material/Settings';
// import CloseIcon from '@mui/icons-material/Close';
// import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import Edit from '@mui/icons-material/Edit';
import Skeleton from '@mui/material/Skeleton';



function VideoCard(props) {
    const {
        loading = false,
        videoData,
        onMoreClick,
    } = props;

    const onEdit = useCallback((e) => onMoreClick(e, videoData), [videoData, onMoreClick]);

    const jwplayerID = useMemo(() => {
        // videoData.url
        let _jwplayerID = "---";
        const url = videoData.url;

        if (url.includes('jwplayer')) {
            /* example */
            // raw https://cdn.jwplayer.com/videos/zJdsoB1u.mp4
            // looking for this https://cdn.jwplayer.com/videos/--->zJdsoB1u<----.mp4
            const urlParts = url.split("/");
            const urlLastPart = urlParts[urlParts.length-1];
            const urlLastPartSplited = urlLastPart.split('.');
            _jwplayerID = urlLastPartSplited[0];
        }

        return _jwplayerID;
    }, [videoData.url]);


    return (
        <Card sx={{ maxWidth: 280, m: 2 }}>
            <CardHeader
                avatar={
                    loading ? (
                        <Skeleton animation="wave" variant="circular" width={40} height={40} />
                    ) : (
                        <Avatar
                            alt="Source logo"
                            // src="https://pbs.twimg.com/profile_images/877631054525472768/Xp5FAPD5_reasonably_small.jpg"
                            src={videoData.source.logoUrl}
                        />
                    )
                }
                action={
                    loading ? null : (
                        <IconButton
                            // disabled={true}
                            aria-label="settings"
                            onClick={onEdit}
                        >
                            <MoreVertIcon
                            />
                        </IconButton>
                    )
                }
                title={
                    loading ? (
                        <Skeleton
                            animation="wave"
                            height={10}
                            width="80%"
                            style={{ marginBottom: 6 }}
                        />
                    ) : (
                        // 'Ted'
                        videoData.title
                    )
                }
                subheader={
                    loading ? (
                        <Skeleton animation="wave" height={10} width="40%" />
                    ) : (
                        // '5 hours ago'
                        videoData.subtitle
                        // videoData.date
                    )
                }
            />
            {loading ? (
                <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
            ) : (
                // <CardMedia
                //     component="img"
                //     height="140"
                //     // image="https://pi.tedcdn.com/r/talkstar-photos.s3.amazonaws.com/uploads/72bda89f-9bbf-4685-910a-2f151c4f3a8a/NicolaSturgeon_2019T-embed.jpg?w=512"
                //     image="https://pi.tedcdn.com/r/talkstar-photos.s3.amazonaws.com/uploads/72bda89f-9bbf-4685-910a-2f151c4f3a8a/NicolaSturgeon_2019T-embed.jpg?w=512"
                //     alt="Nicola Sturgeon on a TED talk stage"
                // />
                <ReactPlayer 
                    url={videoData.url} 
                    width='100%'
                    // height='100%'
                    height="140px"
                    controls
                />
            )}

            <CardContent
                sx={{
                    textAlign: "left",
                }}
            >
                {loading ? (
                    <React.Fragment>
                        <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                        <Skeleton animation="wave" height={10} width="80%" />
                    </React.Fragment>
                ) : (
                    <>
                    <Typography variant="body2" color="text.secondary" component="p">
                        {
                            `id: ${videoData.id }`
                        }
                    </Typography>
                    <Typography variant="body2" color="text.secondary" component="p">
                        {
                            `jwplayer id: ${ jwplayerID }`
                        }
                    </Typography>
                    <Typography variant="body2" color="text.secondary" component="p">
                        {
                            `categories: ${videoData.categories.map(item => item.name).join(', ') }`
                        }
                    </Typography>
                    <Typography variant="body2" color="text.secondary" component="p">
                        {
                            `subcategory: ${videoData.subcategory.name }`
                        }
                    </Typography>
                    <Typography variant="body2" color="text.secondary" component="p">
                        {
                            `tags: ${videoData.tags.map(item => item.name).join(', ') }`
                        }
                    </Typography>
                    <Typography variant="body2" color="text.secondary" component="p">
                        {
                            `date: ${videoData.date}`
                            // "Why First Minister of Scotland Nicola Sturgeon thinks GDP is the wrong measure of a country's success:"
                        }
                    </Typography>
                    <Typography variant="body2" color="text.secondary" component="p">
                        {
                            `copyright: ${videoData.copyright }`
                        }
                    </Typography>
                    <Typography variant="body2" color="text.secondary" component="p">
                        {
                            `location (lat, lon): ${videoData.location }`
                        }
                    </Typography>
                    <Typography variant="body2" color="text.secondary" component="p">
                        {
                            `Superseded by (video ID): ${videoData.supersedesPreviousId }`
                        }
                    </Typography>
                    <Typography variant="body2" color="text.secondary" component="p">
                        {
                            `localityScore: ${videoData.localityScore }`
                        }
                    </Typography>
                    <Typography variant="body2" color="text.secondary" component="p">
                        {
                            `newsScore: ${videoData.newsScore }`
                        }
                    </Typography>
                    <Typography variant="body2" color="text.secondary" component="p">
                        sourceUrl: <a href={videoData.source.sourceUrl} target="_blank" rel="noreferrer">{videoData.source.sourceUrl}</a>
                    </Typography>
                    <Typography variant="body2" color="text.secondary" component="p">
                        url: <a href={videoData.url} target="_blank" rel="noreferrer">{videoData.url}</a>
                    </Typography>
                    </>
                )}
            </CardContent>
        </Card>
    );
}


export default VideoCard;
