import React, { useState, useCallback, useMemo } from 'react';
import styles from './styles.module.css';
// import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import apiQueries from "../../services/apiQueries";
import client from "../../services/api";
import { useMutation, useQuery } from "@apollo/client";

import UsersTable from '../../components/UsersTable';
import AddUserDialog from "../AddUserDialog";
import DeleteConfirmation from '../../components/Modals/UserDeleteConfirmation';
import ChangePasswordConfirmation from '../../components/Modals/ChangePasswordConfirmation';
import { Alert, Backdrop, CircularProgress, InputAdornment, Snackbar, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import useLoggedUserStore from "../../state-store/useLoggedUserStore";

const UsersScreen = () => {
    /* -------------------------------------------------------------------- */
    const [searchString, setSearchString] = useState("");
    const handleSearchString = useCallback((e) => {
        setSearchString(e.target.value);
    }, [setSearchString]);

    /* -------------------------------------------------------------------------------------------- */

    const loggedUserEmail = useLoggedUserStore(state => state.email);

    /* -------------------------------------------------------------------------------------------- */
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState('error');
    const [alertMessage, setAlertMessage] = useState('');

    const handleCloseAlert = useCallback(() => {
        setAlertOpen(false);
    }, [setAlertOpen]);

    /* -------------------------------------------------------------------------------------------- */
    const { loading, error, data, refetch } = useQuery(apiQueries.QUERY_USERS, {
        variables: {
            input: {
                roles: searchString ? null : ["admin", "editor"],
                search: searchString,
            },
        },
        client,
        fetchPolicy: 'no-cache',
    });

    // console.log("loading, error, data, refetch", { loading, error, data, })
    
    const dataList = useMemo(() => {
        let list = [];
        
        if (data) {
            list.push(...data.users.map(item => item));
        }
        
        return list;
    }, [data]);

    
    const [mutateDeleteUser, { /* data:dataMutation, */ loading: loadingMutation, /* errorMutation */ }] = useMutation(apiQueries.MUTATION_DELETE_USER, {
        client,
        onCompleted({ deleteUser }) {
            if (deleteUser) {
                // console.log("createTag", createTag)
                refetch();

                setAlertOpen(true);
                setAlertSeverity('info');
                setAlertMessage('User has been deleted');
            }
        },
        onError: (e) => {
            setAlertOpen(true);
            setAlertSeverity('error');
            setAlertMessage('User deletion error!');

            console.log("MUTATION_DELETE_USER ERROR", JSON.stringify(e, null, 2));
        }
    });

    const [mutateChangePassword, { /* data:dataMutation, */ loading: loadingMutationPass, /* errorMutation */ }] = useMutation(apiQueries.MUTATION_CHANGE_PASSWORD, {
        client,
        onCompleted({ changePassword }) {
            if (changePassword) {
                // console.log("createTag", createTag)
                setAlertOpen(true);
                setAlertSeverity('info');
                setAlertMessage('Password has been changed');
            }
        },
        onError: (e) => {
            setAlertOpen(true);
            setAlertSeverity('error');
            setAlertMessage('Password change error!');

            console.log("MUTATION_CHANGE_PASSWORD ERROR", JSON.stringify(e, null, 2));
        }
    });


    /* -------------------------------------------------------------------------------------------- */
    // const [openEdit, setOpenEdit] = useState(false);
    const [openChangePassword, setOpenChangePassword] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [selectedUserData, setSelectedUserData] = useState({});

    /* -------------------------------------------------------------------------------------------- */
    const [openAddUserDialog, setOpenAddUserDialog] = useState(false);

    const handleOpenAddUserDialog = useCallback(() => {
        setOpenAddUserDialog(true);
    }, [setOpenAddUserDialog]);

    const handleCloseAddUserDialog = useCallback(() => {
        setOpenAddUserDialog(false);
        
        refetch();

    }, [setOpenAddUserDialog, refetch]);

    /* -------------------------------------------------------------------------------------------- */
    // const handleOpenEdit = useCallback((rowData) => {
    //     setSelectedUserData(rowData);
    //     setOpenEdit(true);
    // }, [setOpenEdit]);
    // const handleCloseEdit = useCallback(() => {
    //     setOpenEdit(false);
    // }, [setOpenEdit]);

    /* -------------------------------------------------------------------------------------------- */
    /* -------------------------------------------------------------------------------------------- */
    const handleOpenChangePassword = useCallback((rowData) => {
        setSelectedUserData(rowData);
        setOpenChangePassword(true);
    }, [setOpenChangePassword]);
    const handleCloseChangePassword = useCallback(() => {
        setOpenChangePassword(false);
    }, [setOpenChangePassword]);
    const handleChangePassword = useCallback((rowData, password) => {
        setOpenChangePassword(false);
        const input = {
            email: rowData.email,
            password,
        }
        mutateChangePassword({
            variables: {
                input,
            },
        });
    }, [setOpenChangePassword, mutateChangePassword]);

    /* -------------------------------------------------------------------------------------------- */
    const handleOpenDelete = useCallback((rowData) => {
        if (loggedUserEmail === rowData.email) {
            setAlertOpen(true);
            setAlertSeverity('warning');
            setAlertMessage('You cannot delete yoursef!');
        } else {
            setSelectedUserData(rowData);
            setOpenDelete(true);
        }
    }, [loggedUserEmail, setOpenDelete, setSelectedUserData, setAlertOpen, setAlertSeverity, setAlertMessage]);
    const handleCloseDelete = useCallback(() => {
        setOpenDelete(false);
    }, [setOpenDelete]);
    const handleDelete = useCallback(() => {
        setOpenDelete(false);
        if (loggedUserEmail !== selectedUserData.email) {
            const input = {
                id: selectedUserData.id,
            };
            mutateDeleteUser({
                variables: {
                    input,
                },
            });
        }
    }, [loggedUserEmail, selectedUserData, setOpenDelete, mutateDeleteUser]);
    /* -------------------------------------------------------------------------------------------- */

    return (
        <div className={styles['user-list-main']}>
            <div className={styles['users-list-bar']}>
                <Button
                    variant="contained"
                    color="primary"
                    // disabled={true}
                    onClick={handleOpenAddUserDialog}
                >
                    + Add user
                </Button>
                {/* <TextField
                    id="standard-search"
                    label="Search field"
                    type="search"
                    disabled={true}
                /> */}
                <TextField
                    id="standard-search"
                    label="Search"
                    type="search"
                    // disabled={true}
                    sx={{
                        margin: '4px 0',
                        minWidth: 300,
                    }}

                    value={searchString}
                    onChange={handleSearchString}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
            </div>

            <div className={styles['tableWrapper']}>
                {(loading) ? "Loading..." :
                    ((error) ?
                        <>
                            <div>Error {JSON.stringify(error, null, 2)}</div>
                        </>
                        :
                        <UsersTable
                            rowsData={dataList}
                            // onEdit={handleOpenEdit}
                            onDelete={handleOpenDelete}
                            onChangePassword={handleOpenChangePassword}
                        />
                    )
                }
            </div>
            <AddUserDialog
                open={openAddUserDialog}
                handleClose={handleCloseAddUserDialog}
            />
            <DeleteConfirmation
                open={openDelete}
                itemData={selectedUserData}
                onCancel={handleCloseDelete}
                onConfirm={handleDelete}
            />
            <ChangePasswordConfirmation
                open={openChangePassword}
                itemData={selectedUserData}
                onCancel={handleCloseChangePassword}
                onConfirm={handleChangePassword}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingMutation || loadingMutationPass}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
            >
                <Alert
                    onClose={handleCloseAlert}
                    severity={alertSeverity}
                    sx={{ width: '100%' }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
        </div>
    )
}


export default UsersScreen;
