import React from 'react'
import styles from './styles.module.css'


export const WrapperComponent = (props) => {
    return (
        <div
            className={styles.wrapper}
        >
            {props.children}
        </div>
    )
}
