import React, { useCallback, useState, useMemo } from 'react'
// import Input from './Input';
import Table from './Table';
import styles from './styles.module.css';
import { Alert, Snackbar } from '@mui/material';
// import CategoryEditModal from '../Modals/CategoryEditModal';
import CategoryDeleteModal from '../Modals/CategoryDeleteModal';
import apiQueries from "../../services/apiQueries";
import client from "../../services/api";
import { useMutation, useQuery } from "@apollo/client";
import Button from '@mui/material/Button';
import AddCategoryDialog from '../../screens/AddCategoryDialog';
import UpdateCategoryDialog from '../../screens/UpdateCategoryDialog';


const CategoriesConfigs = () => {
    const { loading: fetchLoading,/*  error, */ data: fetchData, refetch } = useQuery(apiQueries.QUERY_CATEGORIES, {
        client,
        fetchPolicy: 'no-cache',
    });

    const sortedItems = useMemo(() => {
        let list = [];
        if (fetchData) {
            const listCopy = fetchData.categories.map(item => item);
            list = listCopy.sort((a, b) => {
                // return (a.id - b.id)
                return (b.id - a.id)
            });
        }

        return list;
    }, [fetchData]);
    /* -------------------------------------------------------------------------------------------- */
    // const [mutateCreate, { /* data:dataMutation, */ loading: createLoading, /* errorMutation */ }] = useMutation(apiQueries.MUTATION_CREATE_CATEGORY, {
    //     client,
    //     onCompleted({ createCategory }) {
    //         if (createCategory) {
    //             // console.log("createCategory", createCategory)
    //             refetch();

    //             setAlertOpen(true);
    //             setAlertSeverity('success');
    //             setAlertMessage('Category has been created');
    //         }
    //     },
    //     onError: (e) => {
    //         setAlertOpen(true);
    //         setAlertSeverity('error');
    //         setAlertMessage('Failed to create category');

    //         if (e.graphQLErrors) {
    //             if (e.graphQLErrors[0]) {
    //                 if (e.graphQLErrors[0].message) {
    //                     setAlertMessage(e.graphQLErrors[0].message);
    //                 }
    //             }
    //         }
    //         // "graphQLErrors": [
    //         //     {
    //         //       "message": "Category already exists"
    //         console.log("MUTATION_CREATE_CATEGORY ERROR", e);
    //         console.log("MUTATION_CREATE_CATEGORY ERROR", JSON.stringify(e, null, 2));
    //     }
    // });
    // /* -------------------------------------------------------------------------------------------- */
    // const [mutateUpdate, /*{ data:dataMutation, */ /* loading: updateLoading, */ /* errorMutation }*/] = useMutation(apiQueries.MUTATION_UPDATE_CATEGORY, {
    //     client,
    //     onCompleted({ updateCategory }) {
    //         if (updateCategory) {
    //             // console.log("updateCategory", updateCategory)
    //             refetch();
    //             setAlertOpen(true);
    //             setAlertSeverity('success');
    //             setAlertMessage('Category has been updated');
    //         }
    //     },
    //     onError: (e) => {
    //         setAlertOpen(true);
    //         setAlertSeverity('error');
    //         setAlertMessage('Failed to update category');

    //         console.log("MUTATION_UPDATE_CATEGORY ERROR", e);
    //         console.log("MUTATION_UPDATE_CATEGORY ERROR", JSON.stringify(e, null, 2));
    //     }
    // });
    /* -------------------------------------------------------------------------------------------- */
    const [mutateDelete, /*{ data:dataMutation, */ /* loading: deleteLoading, */ /* errorMutation }*/] = useMutation(apiQueries.MUTATION_DELETE_CATEGORY, {
        client,
        onCompleted({ deleteCategory }) {
            if (deleteCategory) {
                // console.log("deleteCategory", deleteCategory)
                refetch();

                setAlertOpen(true);
                setAlertSeverity('success');
                setAlertMessage('Category has been deleted');
            }
        },
        onError: (e) => {
            setAlertOpen(true);
            setAlertSeverity('error');
            setAlertMessage('Failed to delete category');

            console.log("MUTATION_DELETE_CATEGORY ERROR", e);
            console.log("MUTATION_DELETE_CATEGORY ERROR", JSON.stringify(e, null, 2));
        }
    });
    /* -------------------------------------------------------------------------------------------- */


    /* -------------------------------------------------------------------------------------------- */
    const [selectedItem, setSelectedItem] = useState({});
    /* -------------------------------------------------------------------------------------------- */
    const [openAddCategoryDialog, setOpenAddCategoryDialog] = useState(false);
    const handleOpenAddCategoryDialog = useCallback(() => {
        setOpenAddCategoryDialog(true);
    }, [setOpenAddCategoryDialog]);
    
    const handleCloseAddCategoryDialog = useCallback(() => {
        setOpenAddCategoryDialog(false);
    }, [setOpenAddCategoryDialog]);
    
    /* -------------------------------------------------------------------- */
    const [openEditCategoryDialog, setOpenEditCategoryDialog] = useState(false);
    const handleOpenEditCategoryDialog = useCallback((itemData) => {
        setSelectedItem(itemData);
        setOpenEditCategoryDialog(true);
    }, [setSelectedItem, setOpenEditCategoryDialog]);
    
    const handleCloseEditCategoryDialog = useCallback(() => {
        setOpenEditCategoryDialog(false);
    }, [setOpenEditCategoryDialog]);
    /* -------------------------------------------------------------------- */

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState('error');
    const [alertMessage, setAlertMessage] = useState('');

    const handleCloseAlert = useCallback(() => {
        setAlertOpen(false);
    }, [setAlertOpen]);

    /* -------------------------------------------------------------------------------------------- */

    /* -------------------------------------------------------------------------------------------- */
    const [openDelete, setOpenDelete] = useState(false);
    const handleOpenDelete = useCallback((rowData) => {
        setSelectedItem(rowData);
        setOpenDelete(true);
    }, [setOpenDelete, setSelectedItem]);

    const handleCloseDelete = useCallback(() => {
        setOpenDelete(false);
    }, [setOpenDelete]);

    const handleDelete = useCallback(() => {
        // console.log("handleDelete", selectedItem);

        setOpenDelete(false);
        mutateDelete({
            variables: {
                id: selectedItem.id,
            },
        });
    }, [selectedItem, setOpenDelete, mutateDelete]);
    /* -------------------------------------------------------------------------------------------- */

    /* -------------------------------------------------------------------------------------------- */
    // const [openEdit, setOpenEdit] = useState(false);
    // const handleOpenEdit = useCallback((rowData) => {
    //     setSelectedItem(rowData);
    //     setOpenEdit(true);
    // }, [setOpenEdit, setSelectedItem]);

    // const handleCloseEdit = useCallback(() => {
    //     setOpenEdit(false);
    // }, [setOpenEdit]);

    // const handleEdit = useCallback(({ name }) => {
    //     // console.log("handleEdit", selectedItem);
    //     // console.log("name", name);

    //     setOpenEdit(false);
    //     const input = {
    //         id: selectedItem.id,
    //         name,
    //     };
    //     mutateUpdate({
    //         variables: {
    //             input,
    //         },
    //     });
    // }, [selectedItem, setOpenEdit, mutateUpdate]);
    /* -------------------------------------------------------------------------------------------- */
    // const handleCreate = useCallback(({ name }) => {
    //     const input = {
    //         name,
    //     };
    //     mutateCreate({
    //         variables: {
    //             input,
    //         },
    //     });
    // }, [mutateCreate]);
    /* -------------------------------------------------------------------------------------------- */


    return (<>
        {/* <Input
            existedItems={sortedItems}
            loading={fetchLoading || createLoading}
            onAdd={handleCreate}
        /> */}
        <div
            className={styles["tableWrapper"]}
        >
            <div
                style={{ 
                    margin: '10px 0',
                    textAlign: "left",
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenAddCategoryDialog}
                >
                    + Add category
                </Button>
            </div>

            {fetchLoading ?
                <p>Loading...</p>
                :
                <Table
                    rowsData={sortedItems}
                    // onEdit={handleOpenEdit}
                    onEdit={handleOpenEditCategoryDialog}
                    onDelete={handleOpenDelete}
                />
            }
        </div>

        <AddCategoryDialog
            open={openAddCategoryDialog}
            handleClose={handleCloseAddCategoryDialog}
            refreshCategoriesList={refetch}
        />
        <UpdateCategoryDialog
            itemData={selectedItem}
            open={openEditCategoryDialog}
            handleClose={handleCloseEditCategoryDialog}
            refreshCategoriesList={refetch}
        />
        {/* 
        <CategoryEditModal
            itemData={selectedItem}
            open={openEdit}
            onCancel={handleCloseEdit}
            onConfirm={handleEdit}
        /> */}
        <CategoryDeleteModal
            itemData={selectedItem}
            open={openDelete}
            onCancel={handleCloseDelete}
            onConfirm={handleDelete}
        />
        <Snackbar
            open={alertOpen}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
        >
            <Alert
                onClose={handleCloseAlert}
                severity={alertSeverity}
                sx={{ width: '100%' }}
            >
                {alertMessage}
            </Alert>
        </Snackbar>
    </>)
};


export default CategoriesConfigs;
