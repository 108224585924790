import React, { /* useEffect, useState */ } from 'react';
// import styles from "./styles.module.css";
import ReactPlayer from 'react-player'


const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 270,
    height: 180,
    padding: 4,
    boxSizing: 'border-box',
};

const thumbInner = {
    margin: '0 auto',
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};


export default function VideoThumbs({ previewUrls }) {
    const thumbs = previewUrls.map((url, index) => (
        <div style={thumb} key={index + "thumb"}>
            <div style={thumbInner}>
                <ReactPlayer
                    url={url}
                    width='100%'
                    height='100%'
                    controls
                />
            </div>
        </div>
    ));

    return (
        <aside style={thumbsContainer}>
            {thumbs}
        </aside>
    );
}
