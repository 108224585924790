import create from 'zustand'
import formValidator from "../../utils/formValidator";
import produce from 'immer'


/* validation form */
const requiredFieldsSchema = {
    name: {
        rules: ["required",],
        customMessage: "Name is required"
    },
    logo: {
        rules: ["required",],
        customMessage: "Logo is required"
    },
    logoInactive: {
        rules: ["required",],
        customMessage: "Logo Inactive is required"
    },
};

const fieldsFactory = {
    create(attrs) {
        return {
            name: "",
            logo: "",
            logoInactive: "",
            ...attrs
        };
    },
};


/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
function selectObjectValues(dataItem, neededKeys) {
    const data = {}
    
    neededKeys.forEach((key)=> {
        if (dataItem[key] === 0 || !!dataItem[key]) {
            data[key] = dataItem[key];
        }
    });

    return data;
}

function setAllKeysTo(someobject, value) {
    for (let key in someobject) {
        someobject[key] = value;
    }
}

function setAllTouchedFactory(set) {
    return (isTouched=true) => {
        set(produce(state => {
            setAllKeysTo(state.touched, isTouched);
        }))
    }
}


const defaultFormData = fieldsFactory.create();
const defaultTouchedFields = fieldsFactory.create();
setAllKeysTo(defaultTouchedFields, false);
const _defaultErrorsMessages = fieldsFactory.create()
setAllKeysTo(_defaultErrorsMessages, '');
const defaultErrorsMessages = {
    ..._defaultErrorsMessages,
    ...formValidator.isValid(defaultFormData, requiredFieldsSchema).errorsMessages,
};

const useStoreFormRequired = create((set, get) => ({
    isValid: false,
    touched: defaultTouchedFields,
    errorsMessages: defaultErrorsMessages,
    formData: fieldsFactory.create(),

    setDefault: (itemData) => {
        let selectedValues = {}
        if (itemData) {
            const keys = Object.keys(fieldsFactory.create());
            selectedValues = selectObjectValues(itemData, keys);
        }

        const defaultFormData = fieldsFactory.create(selectedValues);
        const _defaultErrorsMessages = fieldsFactory.create()
        setAllKeysTo(_defaultErrorsMessages, '');
        const { isValid, errorsMessages } = formValidator.isValid(defaultFormData, requiredFieldsSchema);
        const defaultErrorsMessages = {
            ..._defaultErrorsMessages,
            ...errorsMessages,
        };


        set({
            isValid,
            touched: defaultTouchedFields,
            errorsMessages: defaultErrorsMessages,
            formData: defaultFormData,
        });
    },
    setAllTouched: setAllTouchedFactory(set),
    onTouch: (fieldName) => {   
        const { isValid, errorsMessages } = formValidator.isValid(
            get().formData,
            requiredFieldsSchema,
        );

        set(produce(state => {
            for (let key in errorsMessages) {
                state.errorsMessages[key] = errorsMessages[key];
            }

            state.isValid = isValid;
            state.touched[fieldName] = true;
        }));
    },
    onChange: (fieldName, value) => {
        const newFormData = {
            ...get().formData,
            [fieldName]: value,
        };

        const { isValid, errorsMessages } = formValidator.isValid(
            newFormData,
            requiredFieldsSchema,
        );

        set(produce(state => {
            state.formData[fieldName] = value;
            state.errorsMessages[fieldName] = errorsMessages[fieldName];
            state.isValid = isValid;
            state.touched[fieldName] = true;
        }));
    },
}))


export {
    useStoreFormRequired,
};
